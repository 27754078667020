<template>
  <article id="sidebar">
    <ReadingReport v-if="$store.state.readingReport" :selectedExam="selectedExam" @update="updateReport"/>
    <ModalView v-if="$store.state.modalViewModal" @close_modal="closeModal" :type="modalType"/>
    <LogoutModal v-if="$store.state.logoutModal" @closeLogoutModal="$store.state.logoutModal=false"/>
    <RequestHistoryModal v-if="$store.state.reqHistoryModal" @close_req_history_modal="$store.state.reqHistoryModal=false"/>
    <ChangePrivateInfoModal v-if="$store.state.changePrivateInfoModal" @close_change_privateInfo_modal="$store.state.changePrivateInfoModal = false"/>

    <div :class="{'category':true,'stretched':stretched}">
      <div id="cate_home" class="cate highlight on">
        <a>
          <div class="tag">
            <img class="off" src="@/assets/images/mn_gnb3.png" alt="" />
            <img class="on" src="@/assets/images/mn_gnb3_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_REQUEST}}<!--원격판독 의뢰--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_REQUEST}}<!--원격판독 의뢰--></p>
        </div>
      </div>
      <div id="cate_req" class="cate highlight">
        <a @click="showModal('request_all')">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_multi.png" alt="" />
            <img class="on" src="@/assets/images/mn_multi_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_REQUEST_MULTIPLE}}<!--원격판독 일괄 의뢰--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_REQUEST_MULTIPLE}}<!--원격판독 일괄 의뢰--></p>
        </div>
      </div>
      <div id="cate_print" class="cate highlight">
        <a @click="showModal('print_all')">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_gnb4.png" alt="" />
            <img class="on" src="@/assets/images/mn_gnb4_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_PRINT_MULTIPLE}}<!--판독소견서 다운로드--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_PRINT_MULTIPLE}}<!--판독소견서 다운로드--></p>
        </div>
      </div>

      <div></div>

      <div id="cate_member" class="cate highlight">
        <a @click="changeLang">
          <div class="tag">
            <img class="off" src="@/assets/images/langOff.png" alt="" />
            <img class="on" src="@/assets/images/langOn.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_SET_LANG}}<!--언어설정--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_SET_LANG}}<!--언어설정--></p>
        </div>
      </div>
      <div id="cate_member" class="cate highlight">
        <a @click="$store.state.alarmHistoryModal=true">
          <div class="tag">
            <img class="off" src="@/assets/images/historyOff.png" alt="" />
            <img class="on" src="@/assets/images/historyOn.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_ALARM_HISTORY}}<!--알림목록--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_ALARM_HISTORY}}<!--알림목록--></p>
        </div>
      </div>
      <div id="cate_help" class="cate highlight">
        <a href="https://panvi.kr/help/usage" target="_blank">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_help.png" alt="" />
            <img class="on" src="@/assets/images/mn_help_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_MANUAL}}<!--사용 설명서--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_MANUAL}}<!--사용 설명서--></p>
        </div>
      </div>
      <div id="cate_stat" class="cate highlight">
        <a @click="$store.state.reqHistoryModal=true">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_util_icon1.png" alt="" />
            <img class="on" src="@/assets/images/mn_util_icon1_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_HISTORY}}<!--판독의뢰 내역--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_HISTORY}}<!--판독의뢰 내역--></p>
        </div>
      </div>
      <div id="cate_member" class="cate highlight">
        <a @click="$store.state.myInfoModal=true">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_util_icon2.png" alt="" />
            <img class="on" src="@/assets/images/mn_util_icon2_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_MYINFO}}<!--내 정보--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_MYINFO}}<!--내 정보--></p>
        </div>
      </div>
      <div id="cate_logout" class="cate highlight">
        <a style="cursor:pointer;" @click="$store.state.logoutModal=true">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_util_icon3.png" alt="" />
            <img class="on" src="@/assets/images/mn_util_icon3_on.png" alt="" />
          </div>
          <div class="title">
            <p>{{lang[$store.state.lang].MENU_LOGOUT}}<!--로그아웃--></p>
          </div>
        </a>
        <div class="tool_tip">
          <p>{{lang[$store.state.lang].MENU_LOGOUT}}<!--로그아웃--></p>
        </div>
      </div>
      <div id="cate_stretch" class="cate">
        <a style="cursor:pointer;" @click="toggleStretched">
          <div class="tag">
            <img class="off" src="@/assets/images/mn_util_icon4.png" alt="" />
            <img class="on" src="@/assets/images/mn_util_icon4_on.png" alt="" />
          </div>
        </a>
      </div>
    </div>
  </article>
</template>

<script>
import ModalView from './ModalView.vue'
import LogoutModal from './LogoutModal.vue'
import RequestHistoryModal from './RequestHistoryModal.vue'
import lang from '@/lang'
import ChangePrivateInfoModal from '@/components/ChangePrivateInfoModal.vue'
import ReadingReport from '@/components/ReadingReport.vue'



export default {
  name: "MainSidebar",
  props: ['selectedExam'],
  components : {
    ModalView,
    LogoutModal,
    RequestHistoryModal,
    ChangePrivateInfoModal,
    ReadingReport
  },
  data: function() {
    return {
      lang,
      stretched: false,
      modalType: 'print_all', // modalType = 'print_all' || 'request_all'
    }
  },
  methods: {
    toggleStretched: function() {
      this.stretched = !this.stretched;
    },
    showModal(type){
      this.$store.state.modalViewModal = true
      this.modalType = type;
    },
    closeModal(){
      this.$store.state.modalViewModal = false;
      this.$store.state.modalCalendar = false;
      this.modalType = 'none';
    },
    changeLang(){
      if(this.$store.state.lang == 'ko') sessionStorage.setItem('lang','en');
      else if (this.$store.state.lang == 'en') sessionStorage.setItem('lang','ko');
      window.location.reload(true);
    },
    updateReport(report){
      this.$emit('updateReport',report);
    },
  },
}
</script>

<style scoped>
  a{
    cursor: pointer;
  }
</style>