import Vue from 'vue'
import Vuex from 'vuex'
import wsEvent from './wsEvent'
import {Buffer} from 'buffer'

// 스토어 생성
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 언어
    lang: "ko",
    // 웹소켓 상태
    socket: {
      isConnected: false, // 연결상태
      isLoggedIn: false, // 로그인상태
      login: {
        id: undefined,
        passwd: undefined
      }, // 로그인정보
      member: null, // 회원정보
      reconnectError: false
    },
    crossSectionModal : false, // ct단면모달상태
    readingReport : false, // 판독소견서상태
    listCalendar: false, // List 컴포넌트 내부의 달력모달 상태
    modalCalendar: false, // ModalView 컴포넌트 내부의 달력모달 상태,
    logoutModal: false, // Logout 컴포넌트 상태,
    modalViewModal: false, // ModalView 컴포넌트의 상태,
    reqHistoryModal: false, // RequestHistoryModal 컴포넌트의 상태
    myInfoModal: false, // MyInfoModal 컴포넌트의 상태
    changePrivateInfoModal: false, // ChangePrivateInfoModal 컴포넌트 상태
    alarmHistoryModal: false, // AlarmHistoryModal 컴포넌트의 상태
    signature : '', // 서명이미지
    hospitalInfo : { 
      name: '',
      addr1: '',
      addr2: '',
      ceo: '',
      company_no: '', 
      contact: '',
      taxInfo: ''
    }, // 병원정보
    imgScrollContainerOpacity: 0, // 영상 opacity
    requestedExams: [], // 여러장 판독의뢰시, 의뢰한 영상 seq
    doctorInfo : { 
      name: '', 
      license: '', 
      phone: '',
      email: ''
    } // 원장정보
  },
  mutations: { // $store.commit() 으로 호출
    /*
    ** 웹소켓 이벤트 리스너
    */
    SOCKET_ONOPEN (state, event)  {
      Vue.prototype.$socket = event.currentTarget;
      Vue.prototype.$$recvBuf = Buffer.alloc(0);
      state.socket.isConnected = true;
      wsEvent.$emit("connected");
    },
    SOCKET_ONCLOSE (state)  {
      state.socket.isConnected = false;
      wsEvent.$emit("disconnected");
    },
    SOCKET_ONERROR (state, event)  {
      wsEvent.$emit("error", event);
    },
    SOCKET_RECONNECT(state, count) {
      state.socket.isConnected = true;
      Vue.prototype.$$recvBuf = Buffer.alloc(0);
      wsEvent.$emit("reconnected", count);
    },
    SOCKET_ONMESSAGE (state, message)  {
      // MessageEvent타입의 message를 Buffer타입으로 가공한다.
      message.data.arrayBuffer().then(function(arrBuf) {
        let buf = Buffer.from(arrBuf);
        Vue.prototype.$$recvBuf = Buffer.concat([Vue.prototype.$$recvBuf,buf]);
        // buf를 프로토콜에 따라 파싱한다.
        if( Vue.prototype.$$recvBuf.length<2 ) return;
        let res = {
          action: Vue.prototype.$$recvBuf.readUInt16BE(0),
          body: [],
          id: -1
        };
        if( Vue.prototype.$$recvBuf.length<3 ) return;
        res.id = Vue.prototype.$$recvBuf.readUInt8(2);
        if( Vue.prototype.$$recvBuf.length<4 ) return;
        let bodyCount = Vue.prototype.$$recvBuf.readUInt8(3), bufIdx = 4;
        if( Vue.prototype.$$recvBuf.length<4+(bodyCount*5) ) return;
        for(let i=0; i<bodyCount; i+=1) {
          res.body.push({
            contentType: Vue.prototype.$$recvBuf.readUInt8(bufIdx),
            length: Vue.prototype.$$recvBuf.readUInt32BE(bufIdx+1)
          });
          bufIdx += 5;
        }
        if( Vue.prototype.$$recvBuf.length<4+(bodyCount*5)+res.body.reduce((t,p)=>t+p.length,0)) return;
        for(let i=0; i<res.body.length; i+=1) {
          res.body[i].content = Vue.prototype.$$recvBuf.subarray(bufIdx,bufIdx+res.body[i].length);
          if(res.body[i].contentType==1)
            res.body[i].content = JSON.parse(res.body[i].content.toString());
          bufIdx+=res.body[i].length;
        }
        Vue.prototype.$$recvBuf = Vue.prototype.$$recvBuf.subarray(bufIdx);
        // 파싱이 완료된 응답데이터는 이벤트버스로 전달한다.
        wsEvent.$emit("recv", res);
      });
    },
  },
  actions: { // $store.dispatch() 로 호출
  },
  getters: { // computed로 작동
  }
});