<template>
  <div class="alarm_history">
    <div class="close_btn" @click="$emit('close')">
      <img width="15px" height="15px" src="https://panvi.kr/images/dise_pu_close-48048e892d290a5210d9..png" alt="close_btn">
    </div>
    <div class="scroll_box">
      <div>
        <div class="item" v-for="(item,idx) in histories" :key="'histories-'+idx+'-'+item.name+'-'+item.dateString">
          <div class="pic" v-if="item.type == 'pic'">
            <div class="content">
              <span>{{item.picType}}</span>
              <span>{{item.name}}</span>
              <span>{{item.status == 'complete' ? lang[$store.state.lang].ALARM_HISTORY_TXT_COMPLETE : lang[$store.state.lang].ALARM_HISTORY_TXT_UNREADABLE}}</span>
            </div>
            <div class="hour">
              <span>{{item.elapsed}}</span>
            </div>
          </div>
          <div class="msg" v-if="item.type == 'msg'" @click="$emit('showChatInHistory',item.exam)">
            <div class="content">
              <span>{{item.picType}}</span>
              <span>{{item.name}}</span>
              <span>{{lang[$store.state.lang].ALARM_HISTORY_TXT_NEW_MESSAGE}}<!--새 메시지--></span>
            </div>
            <div class="hour">
              <span>{{item.elapsed}}</span>
            </div>
          </div>
          <div class="charge" v-if="item.type == 'charge'">
            <div class="content">
              <span v-if="$store.state.lang == 'ko'">{{item.dateString.slice(0,4)}}년 {{item.dateString.slice(4,6)}}월 청구내역 도착</span>
              <span v-else>Billing of {{months[parseInt(item.dateString.slice(4,6))-1]}}. {{item.dateString.slice(6,8)}} Arrival</span>
            </div>
            <div class="hour">
              <span>{{item.elapsed}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang'
import wsMixin from '@/wsMixin'
export default {
  name: 'AlarmHistory',
  mixins: [wsMixin],
  data: function(){
    return{
      lang,
      months: ['January','February','March','April','May','Jun','July','August','September','October','November','December'],
      histories: [
        {
          type: 'pic',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-22T02:30:23.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-22T02:30:23.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-22T02:30:23.000Z').getDate())}`,
          status: 'complete',
          elapsed: this.__returnDateElapsed('2023-03-22T02:30:23.000Z'),
        },
        {
          type: 'pic',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-22T01:30:23.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-22T01:30:23.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-22T01:30:23.000Z').getDate())}`,
          status: 'unreadable',
          elapsed: this.__returnDateElapsed('2023-03-22T01:30:23.000Z'),
        },
        {
          type: 'msg',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-21T02:30:23.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-21T02:30:23.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-21T02:30:23.000Z').getDate())}`,
          elapsed: this.__returnDateElapsed('2023-03-21T02:30:23.000Z'),
        },
        {
          type: 'charge',
          dateString: `${new Date('2023-03-18T09:30:23.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-18T09:30:23.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-18T09:30:23.000Z').getDate())}`,
          elapsed: this.__returnDateElapsed('2023-03-18T09:30:23.000Z'),
        },
        {
          type: 'pic',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-16T11:45:00.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getDate())}`,
          status: 'unreadable',
          elapsed: this.__returnDateElapsed('2023-03-16T11:45:00.000Z'),
        },
        {
          type: 'pic',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-16T11:45:00.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getDate())}`,
          status: 'unreadable',
          elapsed: this.__returnDateElapsed('2023-03-16T11:45:00.000Z'),
        },
        {
          type: 'pic',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-16T11:45:00.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getDate())}`,
          status: 'unreadable',
          elapsed: this.__returnDateElapsed('2023-03-16T11:45:00.000Z'),
        },
        {
          type: 'pic',
          picType: 'PX',
          name: '홍길동',
          dateString: `${new Date('2023-03-16T11:45:00.000Z').getFullYear()}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date('2023-03-16T11:45:00.000Z').getDate())}`,
          status: 'unreadable',
          elapsed: this.__returnDateElapsed('2023-03-16T11:45:00.000Z'),
        },
      ]
    }
  },
  methods: {
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      if(data.action == 103){
        if(data.body[0].content.success){
          const nowDate = new Date().toISOString();
          this.histories.unshift({
            type: 'msg',
            picType: 'PX',
            name: 'new',
            dateString: `${new Date(nowDate).getFullYear()}${this.__makeNumberToStringLengthTwo(new Date(nowDate).getMonth()+1)}${this.__makeNumberToStringLengthTwo(new Date(nowDate).getDate())}`,
            elapsed: this.__returnDateElapsed(nowDate),
            exam: data.body[0].content.exam,
          })
        }
      }
    },
    __makeNumberToStringLengthTwo(num){
      return num.toString().length == 2 ? num.toString() : `0${num.toString()}`;
    },
    __returnDateElapsed(dateStr){
      const dateObj = {
        year: new Date(dateStr).getFullYear(),
        month: new Date(dateStr).getMonth()+1,
        date: new Date(dateStr).getDate()
      }
      const today = {
        year: new Date().getFullYear(),
        month: new Date().getMonth()+1,
        date: new Date().getDate()
      }
      const yesterday = {
        year: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1).getFullYear(),
        month: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1).getMonth()+1,
        date: new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()-1).getDate()
      }
      if(new Date(dateObj.year,dateObj.month,dateObj.date).getTime() == new Date(today.year,today.month,today.date).getTime()){
        const diff = new Date().getTime() - new Date(dateStr).getTime();
        let hour = diff / (1000 * 60 * 60 );
        if(hour >= 1) return `${parseInt(hour)}${this.lang[this.$store.state.lang].ALARM_HISTORY_TXT_HOURS_AGO}`; // 시간 전
        else {
          let minute = diff / (1000 * 60);
          return `${parseInt(minute)}${this.lang[this.$store.state.lang].ALARM_HISTORY_TXT_MINUTES_AGO}`; // 분 전
        }
      }else if(new Date(dateObj.year,dateObj.month,dateObj.date).getTime() == new Date(yesterday.year,yesterday.month,yesterday.date).getTime()){
        return this.lang[this.$store.state.lang].ALARM_HISTORY_TXT_YESTERDAY; // 어제
      }else{
        return `${dateObj.year}.${this.__makeNumberToStringLengthTwo(dateObj.month)}.${this.__makeNumberToStringLengthTwo(dateObj.date)}`
      }
    }
  },
}
</script>

<style scoped>
  .alarm_history{
    padding-top: 35px;
    padding-bottom: 10px;
    position: absolute;
    left: 10px;
    bottom: 55px;
    width: 285px;
    height: 340px;
    background-color: #212125;
    border: 1px solid #A0A0A0;
    box-sizing: border-box;
    border-radius: 8px;
    z-index: 2;
  }
  .close_btn{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
  }
  .scroll_box{
    height: 100%;
    overflow: auto;
    padding-right: 5px;
    padding-left: 5px;
  }
  .item{
    padding: 0 10px;
    height: 40px;
    margin-bottom: 5px;
    border: 1px solid #2E2E33;
    border-radius: 3px;
    box-sizing: border-box;
  }
  .pic,
  .msg,
  .charge{
    height: 100%;
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: space-between;
    color: white;
  }
  .content{
    display: flex;
    flex: 1;
    justify-content: space-between;
    font-size: 11px;
  }
  .item .hour{
    width: 50px;
    font-size: 10px;
    text-align: end;
  }
  .pic>.content>span:first-child,
  .msg>.content>span:first-child{width:13px}

  .pic>.content>span:nth-child(2),
  .msg>.content>span:nth-child(2){width:32px}

  .pic>.content>span:last-child,
  .msg>.content>span:last-child{width:103px}
  .scroll_box::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  .scroll_box::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  .scroll_box::-webkit-scrollbar-thumb {
    background-color: #403F45;
  }
</style>