<template>
  <div class="logout_modal">
    <div class="modal_box">
      <div class="text_wrap">
        <h3>{{lang[$store.state.lang].LOGOUT_TXT_TITLE}}</h3> <!--로그아웃 하시겠습니까?-->
      </div>
      <div>
        <div class="button_wrap">
          <button class="basic_btn black_btn" @click="$emit('closeLogoutModal')">{{lang[$store.state.lang].LOGOUT_BUTTON_TXT_CANCEL}}<!--취소--></button>
          <button class="basic_btn purple_btn" @click="logout">{{lang[$store.state.lang].LOGOUT_BUTTON_TXT_CONFIRM}}<!--로그아웃 합니다--></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wsMixin from '@/wsMixin';
import lang from '@/lang';
import alert from '@/alert';

export default {
  name : 'LogoutModal',
  mixins: [wsMixin],
  data: function(){
    return {
      lang,
    }
  },
  methods : {
    alert,
    logout(){
      let packet = this.$$build(12,[]);
      this.$socket.send(packet);
    },
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      // 12번 action(= 로그아웃)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 12){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          sessionStorage.removeItem('id');
          sessionStorage.removeItem('passwd');
          this.$store.state.socket.isLoggedIn = false;
          this.$store.state.logoutModal = false;
          this.$store.state.myInfoModal = false;
          this.$store.state.readingReport = false;
          this.$store.state.modalViewModal = false;
          this.$store.state.reqHistoryModal = false;
          this.$store.state.changePrivateInfoModal = false;
          this.$store.state.alarmHistoryModal = false;
          this.$store.state.signature = '';
          for(let key in this.$store.state.hospitalInfo){
            this.$store.state.hospitalInfo[key] = '';
          }

          for(let key in this.$store.state.doctorInfo){
            this.$store.state.doctorInfo[key] = '';
          }
          this.$router.push('/');
        }
        // 응답이 실패인 경우에 대한 처리
        else {
          this.alert('error',lang[this.$store.state.lang].LOGOUT_ALERT_TXT); // 로그아웃에 실패하셨습니다.\n다시 시도해주세요
        }
      }
    }
  },
}
</script>

<style scoped>
  .logout_modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 5;
  }
  .modal_box{
    width: 550px;
    height: 350px;
    padding: 40px;
    display: grid;
    grid-template-rows: 2fr 1fr;
    place-items: center center;
    box-sizing: border-box;
    background-color: #1A1A1B;
    border-radius: 10px;
    border: 1px solid #404040;
  }
  .text_wrap{
    padding-top: 40px;
  }
  .text_wrap h3{
    font-size: 22px;
    color: #fff;
    text-align: center;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
  }
  .button_wrap{
    display: grid;
    place-items: center center;
    grid-template-columns: 80px auto;
    width: 100%;
    grid-gap: 16px;
  }
  .button_wrap button{
    width: 100%;
  }
  .button_wrap .purple_btn {
    min-width: 80px;
    padding: 0 13px;
  }
  .basic_btn{
    height: 44px;
    background-color: #0e0e0e;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 600;
  }
  .black_btn{
    color: #DFDEE0;
    border: 1px solid rgb(84, 84, 88);
    box-sizing: border-box;
  }
  .purple_btn{
    color: white;
    box-sizing: border-box;
    border: 1px solid #5510B5;
    font-weight: 700;
  }
  .purple_btn:hover{
    background: #9560F5;
  }
</style>