<template>
  <div class="reading_report" style="left:0; bottom:0" @mousedown="mousedownOnReport" @mousemove="moveReport" @mouseup="endMoving" @mouseleave="endMoving" ref="report">
    <button class="close_btn" @click="$store.state.readingReport=false">
      <img src="https://panvi.kr/images/print_pu_close-273c93da6d1129f12a26..png" alt="close_btn">
    </button>
    <div class="title">
      <h3>{{lang[$store.state.lang].REPORT_TXT_TITLE}}<!--판독소견서--></h3>
    </div>
    <div class="form">
      <div class="print_table">
        <div class="table_head">
          <div class="row_top">
            <div class="col_left" v-html="lang[$store.state.lang].REPORT_TXT_PATIENT"></div><!--촬영환자-->
            <div class="col_right">
              <div class="inner_row">
                <div>{{lang[$store.state.lang].REPORT_TXT_PATIENT_NAME}}<!--이름--></div>
                <div>{{selectedExam.patient_name}}</div>
                <div>{{lang[$store.state.lang].REPORT_TXT_CHART_NUMBER}}<!--차트번호--></div>
                <div>{{selectedExam.chart_id}}</div>
              </div>
              <div class="inner_row">
                <div>{{lang[$store.state.lang].REPORT_TXT_PATIENT_AGE}}<!--나이--></div>
                <div>{{selectedExam.patient_age}}</div>
                <div>{{lang[$store.state.lang].REPORT_TXT_EXAM_DATE}}<!--촬영일시--></div>
                <div>{{selectedExam.taken.slice(0,4)}}.{{selectedExam.taken.slice(5,7)}}.{{selectedExam.taken.slice(8,10)}}</div>
              </div>
              <div class="inner_row">
                <div>{{lang[$store.state.lang].REPORT_TXT_PATIENT_GENDER}}<!--성별--></div>
                <div>{{selectedExam.patient_gender}}</div>
                <div>{{lang[$store.state.lang].REPORT_TXT_IMAGE_TYPE}}<!--영상종류--></div>
                <div>{{selectedExam.type=='CT'?'CBCT':'PANORAMA'}}</div>
              </div>
            </div>
          </div>
          <div class="row_bottom">
            <div class="col_left">C.C</div>
            <div class="col_right cc_text">
              <textarea name="cc" :value="cc" @input="updateText" ref="cc" :placeholder="lang[$store.state.lang].REPORT_TXT_PLACEHOLDER"></textarea>
            </div>
          </div>
        </div>
        <div class="table_body">
          <div class="finding_row">
            <div class="table_body_head">
              <h3>Finding</h3>
            </div>
            <div>
              <textarea name="finding" :value="finding" @input="updateText" ref="finding" :placeholder="lang[$store.state.lang].REPORT_TXT_PLACEHOLDER"></textarea>
            </div>
          </div>
          <div class="diagnosis_row">
            <div class="table_body_head">
              <h3>Diagnosis</h3>
            </div>
            <div>
              <textarea name="diagnosis" :value="diagnosis" @input="updateText" ref="diagnosis" :placeholder="lang[$store.state.lang].REPORT_TXT_PLACEHOLDER"></textarea>
            </div>
          </div>
          <div class="recommendation_row">
            <div class="table_body_head">
              <h3>Recommendation</h3>
            </div>
            <div>
              <textarea name="recommendation" :value="recommendation" @input="updateText" ref="recommendation" :placeholder="lang[$store.state.lang].REPORT_TXT_PLACEHOLDER"></textarea>
            </div>
          </div>
        </div>
        <div class="table_footer">
          <div class="col_left">{{lang[$store.state.lang].REPORT_TXT_RADIOLOGIST}}<!--판독자--></div>
          <div class="col_middle">
            <div class="middle_inner_row">
              <div>{{lang[$store.state.lang].REPORT_TXT_HOSPITAL_NAME}}<!--치과명--></div>
              <div>
                <input type="text" v-model="radiologistInfo.hospitalName">
              </div>
              <div>{{lang[$store.state.lang].REPORT_TXT_DIAGNOSIS_DATE}}<!--판독일자--></div>
              <div>
                <input type="text" v-model="radiologistInfo.diagnosisDate">
              </div>
            </div>
            <div class="middle_inner_row">
              <div>{{lang[$store.state.lang].REPORT_TXT_LICENSE_NUMBER}}<!--면허번호--></div>
              <div>
                <input type="text" v-model="radiologistInfo.license_no">
              </div>
              <div>{{lang[$store.state.lang].REPORT_TXT_RADIOLOGIST_NAME}}<!--성명--></div>
              <div>
                <input type="text" v-model="radiologistInfo.name">
              </div>
            </div>
          </div>
          <div class="col_right" @mouseenter="showRmvBtn = true" @mouseleave="showRmvBtn = false">
            <div class="sign_text">{{lang[$store.state.lang].REPORT_TXT_SEAL}}<!--(인)--></div>
            <img class="sign_img" v-if="signImg" :src="signImg" alt="sign_img">
            <img v-if="signImg&&showRmvBtn" @click="remove_sign" class="remove_sign" src="https://panvi.kr/images/print_pu_close-273c93da6d1129f12a26..png" alt="remove_sign" width="10">
          </div>
        </div>
      </div>
      <div class="print_sign">
        
        <div class="sign_block">
          <p>{{lang[$store.state.lang].REPORT_TXT_IMAGE_REQUIREMENT}}<!--이미지 비율 1:1, 해상도 100Ppx 이상 권장--></p>
          <button class="file_upload_btn">
            <label for="sign_input">{{lang[$store.state.lang].REPORT_TXT_BUTTON_UPLOAD_FILE}}<!--서명파일 업로드--></label>
            <input ref="signInput" id="sign_input" type="file" accept="image/png, image/jpeg, .pdf" @input="getUrlToUpload">
          </button>
        </div>
      </div>
    </div>
    <div class="btns">
      <div class="btns_wrap">
        <div class="btn_left">
          <button class="cancel_btn btn" @click="$store.state.readingReport=false">{{lang[$store.state.lang].REPORT_TXT_BUTTON_CANCEL}}<!--취소--></button>
          <button 
            :disabled="isDisabled"
            class="save_btn btn" 
            @click="saveReport"
          >
            {{lang[$store.state.lang].REPORT_TXT_BUTTON_SAVE}}<!--내용 저장-->
          </button>
        </div>

        <div>
          <button class="download_btn btn" @click="downloadReport">{{lang[$store.state.lang].REPORT_TXT_BUTTON_DOWNLOAD_PDF}}<!--PDF 다운로드--></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wsMixin from '@/wsMixin';
import lang from '@/lang';
import alert from '@/alert'

export default {
  name : 'ReadingReport',
  props : {
    selectedExam : Object
  },
  mixins: [wsMixin],
  data(){
    return{
      lang,
      signImg : '',
      cc : '',
      finding : '',
      diagnosis : '',
      recommendation : '',
      isDisabled : true,
      startPoint: {x:0, y:0},
      endPoint: {x:0, y:0},
      showRmvBtn: false,
      radiologistInfo: {
        hospitalName: '',
        diagnosisDate: '',
        name: '',
        license_no: ''
      }
    }
  },
  methods:{
    alert,
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      // 578번 action(= 판독소견서 입력)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 578){
        // 응답이 성공인 경우 이벤트를 방출하여 상위컴포넌트에서 변경된 판독소견서 정보에 접근할 수 있도록 한다.
        if(data.body[0].content.success){
          this.$emit('update',{cc:this.cc,finding:this.finding,conclusion:this.diagnosis,recommend:this.recommendation});
          this.isDisabled = true;
        }
        // 응답이 실패인 경우에 대한 처리
        else {
          this.isDisabled = false;
          this.alert('error',lang[this.$store.state.lang].REPORT_TXT_ALERT_FAIL_SAVE);
        }
      }
      // 29번 action(= 판독소견서 pdf 저장)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 29){
        if(data.id == 1) {
          if(data.body[0].content.success) {
            if(!data.body[0].content.ready && !data.body[0].content.err) {
              this.alert('default',lang[this.$store.state.lang].MODAL_TXT_DOWNLOAD_PDF); // 잠시후 다운로드가 시작됩니다.
            }
            else if(!data.body[0].content.ready && data.body[0].content.err) this.alert('error',lang[this.$store.state.lang].MODAL_TXT_FAIL_DOWNLOAD_PDF) // pdf 저장에 실패하였습니다.\n다시 시도해주세요.
            else if(data.body[0].content.ready) {
              let key = process.env["VUE_APP_DEBUG"]?process.env["VUE_APP_WEB_DEV_URL"]:process.env["VUE_APP_WEB_URL"];
              window.open(key + "report/"+data.body[0].content.token);
            }
          }else this.alert('error',lang[this.$store.state.lang].MODAL_TXT_FAIL_DOWNLOAD_PDF) // pdf 저장에 실패하였습니다.\n다시 시도해주세요.
        }
      }
    },
    getUrlToUpload(ev){
      let fr = new FileReader();
      fr.onload = (e) => {
        this.signImg = e.target.result;
      };
      fr.readAsDataURL(ev.target.files[0]);
    },
    remove_sign(){
      this.signImg = '';
      this.$refs.signInput.value = '';
    },
    saveReport(){
      let packet = this.$$build(578,[{
        contentType : 1,
        content : {
          exam: this.selectedExam.seq,
          cc : this.cc,
          finding: this.finding,
          conclusion: this.diagnosis,
          recommend : this.recommendation
        }
      }]);
      this.$socket.send(packet);
    },
    updateText(ev){
      // input 이벤트가 발생한 target의 name 속성에 따라 판독소견서의 내용을 변경한다.
      if(ev.target.name == 'cc') this.cc = ev.target.value;
      if(ev.target.name == 'finding') this.finding = ev.target.value;
      if(ev.target.name == 'diagnosis') this.diagnosis = ev.target.value;
      if(ev.target.name == 'recommendation') this.recommendation = ev.target.value;

      // selectedExam의 판독소견서에 하나라도 입력된 값이 있는 속성이 있는 경우에 대한 처리
      if(this.selectedExam.report.cc || this.selectedExam.report.finding || this.selectedExam.report.conclusion || this.selectedExam.report.recommend){
        // selectedExam 판독소견서의 내용과 변경된 내용이 같은 경우에 대한 처리
        if(this.selectedExam.report.cc == this.cc && this.selectedExam.report.finding == this.finding && this.selectedExam.report.conclusion == this.diagnosis && this.selectedExam.report.recommend == this.recommendation){
          this.isDisabled = true;
        }
        // selectedExam 판독소견서의 내용과 변경된 내용이 다른 경우에 대한 처리
        else this.isDisabled = false;
      }
      // selectedExam의 판독소견서에서 모든 속성의 값이 없는 경우에 대한 처리
      else{
        // 입력된 소견서 내용의 길이가 0보다 큰 경우에 대한 처리
        if(this.cc.length > 0 || this.finding.length > 0 || this.diagnosis.length > 0 || this.recommendation.length > 0){
          this.isDisabled = false
        }
        // 입력된 소견서 내용의 길이가 0인 경우에 대한 처리
        else this.isDisabled  = true;
      }
      // textarea의 height를 작성된 value의 양에 맞게 조정한다.
      ev.target.style.height = "1px";
      ev.target.style.height = (ev.target.scrollHeight)+"px";
    },
    // drag move 시작
    mousedownOnReport(ev){
      // 판독소견서 mouse drag 이동시, 해당 조건에 해당하는 경우에는 시작점을 설정하지 않도록 한다.
      if(ev.target.tagName == 'TEXTAREA' || ev.target.tagName == 'LABEL' || ev.target.tagName == 'BUTTON' ) return;
      this.startPoint = {x: ev.clientX, y: ev.clientY};
    },
    // drag move 이동
    moveReport(ev){
      const reportStyle = this.$refs.report.style;
      if(this.startPoint.x == 0) return;
      this.endPoint = {x: ev.clientX, y: ev.clientY};
      
      // 이동한 만큼 report style 의 left, bottom 속성값을 변경한다.
      reportStyle.left = parseInt(reportStyle.left.replace('px','')) + (this.endPoint.x - this.startPoint.x) + 'px';
      reportStyle.bottom = parseInt(reportStyle.bottom.replace('px','')) - (this.endPoint.y - this.startPoint.y) + 'px';
      
      this.startPoint = {...this.endPoint};
    },
    // drag move 종료
    endMoving(){
      this.startPoint = {x: 0, y: 0};
      this.endPoint = {x: 0, y: 0};
    },
    downloadReport(){
      let body = {
        contentType: 1,
        content: {
          exams: [this.selectedExam.seq],
          lang: this.$store.state.lang == 'ko' ? 'kr' : this.$store.state.lang
        }
      }
      let originalReadDate = '';
      if(this.selectedExam.read_at) {
        if(this.$store.state.lang == 'ko') originalReadDate = `${this.selectedExam.read_at.slice(0,4)+'.'}${this.selectedExam.read_at.slice(5,7)+'.'}${this.selectedExam.read_at.slice(8,10)}`;
        else originalReadDate = `${new Date(this.selectedExam.read_at).toLocaleString("en-US", { month: "short" })},${new Date(this.selectedExam.read_at).getDate()} ${new Date(this.selectedExam.read_at).getFullYear()}`;
      }

      if(this.radiologistInfo.license_no != this.$store.state.doctorInfo.license) body.content.license_no = this.radiologistInfo.license_no;
      if(this.radiologistInfo.hospitalName != this.$store.state.hospitalInfo.name) body.content.hospital_name = this.radiologistInfo.hospitalName;
      if(this.radiologistInfo.name != this.$store.state.doctorInfo.name) body.content.doctor_name = this.radiologistInfo.name;
      if(this.radiologistInfo.diagnosisDate != originalReadDate) body.content.read_at = this.radiologistInfo.diagnosisDate;
      if(this.$store.state.signature && (this.$store.state.signature != this.signImg)) body.content.signature = this.signImg;
      else if(!this.$store.state.signature && this.signImg) body.content.signature = this.signImg;

      let packet = this.$$build(29,1,[body]);
      this.$socket.send(packet);
    },
  },
  computed: {
    signature(){
      return this.$store.state.signature;
    },
    hospital_name(){
      return this.$store.state.hospitalInfo.name;
    },
    license_no(){
      return this.$store.state.doctorInfo.license;
    }
  },
  watch: {
    // 서명이 upload되면 바뀐 이미지가 보이도록 한다.
    signature(){
      if(this.$store.state.changePrivateInfoModal) {
        this.signImg = this.$store.state.signature;
      }
    },
    hospital_name(val){
      this.radiologistInfo.hospitalName = val;
    },
    license_no(val){
      this.radiologistInfo.license_no = val;
    }
  },
  mounted(){
    if(this.$store.state.doctorInfo.name) this.radiologistInfo.name = this.$store.state.doctorInfo.name;
    if(this.$store.state.doctorInfo.license) this.radiologistInfo.license_no = this.$store.state.doctorInfo.license;
    if(this.$store.state.hospitalInfo.name) this.radiologistInfo.hospitalName = this.$store.state.hospitalInfo.name;
    if(this.selectedExam.read_at) {
      if(this.$store.state.lang == 'ko') this.radiologistInfo.diagnosisDate = `${this.selectedExam.read_at.slice(0,4)+'.'}${this.selectedExam.read_at.slice(5,7)+'.'}${this.selectedExam.read_at.slice(8,10)}`;
      else this.radiologistInfo.diagnosisDate = `${new Date(this.selectedExam.read_at).toLocaleString("en-US", { month: "short" })},${new Date(this.selectedExam.read_at).getDate()} ${new Date(this.selectedExam.read_at).getFullYear()}`;
    }

    // selectedExam 판독소견서에 하나라도 입력된 값이 있는 속성이 있는 경우에 대한 처리
    if(this.selectedExam.report.cc || this.selectedExam.report.finding || this.selectedExam.report.conclusion || this.selectedExam.report.recommend){
      this.cc = this.selectedExam.report.cc;
      this.finding = this.selectedExam.report.finding;
      this.diagnosis = this.selectedExam.report.conclusion;
      this.recommendation = this.selectedExam.report.recommend;
    }

    // store에 서명정보가 있는 경우에 대한 처리
    if(this.$store.state.signature) this.signImg = this.$store.state.signature;

    // mount 시, textarea의 높이를 scrollHeight 의 높이로 할당하여 작성한 내용의 양에 맞춰 height가 지정되도록 한다.
    this.$nextTick(() => {
      this.$refs.cc.style.height = "1px";
      this.$refs.finding.style.height = "1px";
      this.$refs.diagnosis.style.height = "1px";
      this.$refs.recommendation.style.height = "1px";

      this.$refs.cc.style.height = (this.$refs.cc.scrollHeight)+"px";
      this.$refs.finding.style.height = (this.$refs.finding.scrollHeight)+"px";
      this.$refs.diagnosis.style.height = (this.$refs.diagnosis.scrollHeight)+"px";
      this.$refs.recommendation.style.height = (this.$refs.recommendation.scrollHeight)+"px";
    });
  },
}
</script>

<style scoped>
  .reading_report{
    position: fixed;
    width: 600px;
    height: 80%;
    padding: 20px;
    display: grid;
    grid-template-rows: 50px 1fr 60px;
    box-sizing: border-box;
    background: white;
    border-radius: 10px;
    overflow: hidden;
    z-index: 2;
    cursor: move;
  }
  ::-webkit-scrollbar {
    display: none;
  }
  textarea{
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    resize: none;
    overflow: auto;
    box-sizing: border-box;
  }
  .close_btn{
    position: absolute;
    top: 28px;
    right: 28px;
    cursor: pointer;
  }
  .title > h3{
    font-size: 24px;
    color: #1A1A1B;
    font-weight: 700;
  }
  .form{
    overflow-y: auto;
    overflow-x: hidden;
  }
  .table_head{
    display: grid;
    grid-template-rows: auto auto;
  }
  .row_top{
    display: grid;
    grid-template-columns: 70px 1fr;
    border: 1px solid black;
  }
  .col_left{
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid black;
  }
  .row_top .col_right{
    display: grid;
    grid-template-rows: auto auto auto;
  }
  .inner_row{
    display: grid;
    grid-template-columns: 70px 1fr 70px 1fr;
    height: 35px;
    border-bottom: 1px solid black;
  }
  .inner_row:last-child{
    border-bottom: none;
  }
  .inner_row>div{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-right: 1px solid black;
  }
  .inner_row>div:last-child{
    border-right: none;
  }
  .row_bottom{
    display: grid;
    grid-template-columns: 70px 1fr;
    border: 1px solid black;
    border-top: none;
  }
  .table_body button{
    height: 30px;
    width: 50px;
    background: #0e0e0e;
    color: #DFDEE0;
    border: 1px solid #DFDEE0;
  }
  .finding_row,
  .diagnosis_row,
  .recommendation_row{
    display: grid;
    grid-template-rows: 30px auto;
    border: 1px solid black;
    border-top: none;
  }
  .finding_row textarea{
    min-height: 90px;
  }
  .diagnosis_row textarea,
  .recommendation_row textarea{
    min-height: 60px;
  }
  .table_body_head{
    padding: 0 10px;
    line-height: 30px;
    box-sizing: border-box;
    border-bottom: 1px solid black;
  }
  .table_body_head>h3{
    font-size: 12px;
  }
  .table_footer{
    display: grid;
    grid-template-columns: 70px 1fr 70px;
    border: 1px solid black;
    border-top: none;
  }
  .table_footer .col_right{
    position: relative;
  }
  .table_footer .sign_text{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .table_footer .col_right .sign_img{
    position:absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
  }
  .table_footer .col_right .remove_sign{
    position: absolute;
    top: 5%;
    left: 5%;
    cursor: pointer;
  }
  .table_footer>.col_middle{
    display: grid;
    grid-template-rows: 35px 35px;
  }
  .middle_inner_row{
    display: grid;
    grid-template-columns: 70px 138px 70px 138px;
    border-bottom: 1px solid black;
    border-right:  1px solid black;
  }
  .middle_inner_row input{
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
  }
  .middle_inner_row:last-child{
    border-bottom: none;
  }
  .middle_inner_row>div{
    display: flex;
    justify-content: center;
    align-items: center;
    border-right:  1px solid black;
    text-align:center;
  }
  .middle_inner_row>div:last-child{
    border-right: none;
  }
  .table_footer .col_right{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .print_sign{
    padding-top: 10px;
    display: grid;
    place-items: center right;
  }
  .print_sign p{
    font-size: 12px;
  }
  .sign_block{
    height: 28px;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    place-items: center center;
  }
  .file_upload_btn{
    position: relative;
    min-width: 100px;
    padding: 0 3px;
    height: 100%;
    border: 1px solid #DFDEE0;
    background: #403F45;
    box-sizing: content-box;
    border-radius: 4px;
    color: white;
    font-size: 12px;
  }
  .file_upload_btn label{
    position: absolute;
    top: 50%;
    width: 100%;
    left: 50%;
    color: white;
    cursor: pointer;
    transform: translate(-50%, -50%);
  }
  .file_upload_btn input{
    display: none;
  }
  .btn{
    height: 36px;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .btns{
    display: grid;
    place-items: center center;
  }
  .btns_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .btn_left{
    display: grid;
    gap: 10px;
    grid-template-columns: 64px 83px;
  }
  .cancel_btn{
    border: 1px solid #848488;
    color: #403F45;
  }
  .download_btn{
    min-width: 124px;
    padding: 0 5px;
    background: #574EE0;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
  }
  .save_btn{
    min-width: 80px;
    padding: 0px 3px;
    background: #0e0e0e;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    box-sizing: content-box;
  }
  .save_btn:disabled{
    opacity: 0.6;
    cursor: auto;
  }
  .save_btn > p{
    text-align: center;
  }
  .cc_text textarea{
    min-height: 35px;
  }
  textarea{
    padding: 5px 5px;
    font-size: 13px;
    box-sizing: border-box;
    transition: color 0.3s;
  }
  textarea::placeholder,
  textarea::-webkit-input-placeholder,
  textarea:-ms-input-placeholder {
    color: #848488;
  }
  textarea:focus::-webkit-input-placeholder { /* WebKit browsers */
    opacity: 0;
    transition: opacity 0.3s;
  }
  textarea:focus:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    opacity: 0;
    transition: opacity 0.3s;
  }
  textarea:focus::-moz-placeholder { /* Mozilla Firefox 19+ */
    opacity: 0;
    transition: opacity 0.3s;
  }
</style>