<template>
  <div class="my_info_modal">
    <h3>{{$store.state.hospitalInfo.name}}</h3>
    <p>{{$store.state.doctorInfo.name}}</p>
    <div class="btn_wrap">
      <button @click="$store.state.changePrivateInfoModal = true">{{lang[$store.state.lang].MY_INFO_TXT_EDIT_PRIVATE_INFO}}<!--개인정보 변경--></button>
      <button @click="$emit('show_logoutModal')">{{lang[$store.state.lang].MY_INFO_TXT_LOG_OUT}}<!--로그아웃--></button>
    </div>
    <div class="bar"></div>
    <div class="request_history">
      <div class="title">
        <h4>{{lang[$store.state.lang].MY_INFO_TXT_REQ_HISTORY}}<!--판독의뢰 내역--></h4>
        <div v-if="$store.state.lang == 'ko'">{{date.year}}년 {{date.month}}월</div>
        <div v-else>{{months[date.month -1]}} {{date.year}}</div>
      </div>
      <ul class="case">
        <li>
          <h5>PANORAMA</h5>
          <p>{{cases.panorama + ($store.state.lang == 'ko' ? ' 건' : (cases.panorama > 1 ? ' cases' : ' case'))}}</p>

        </li>
        <li>
          <h5>CBCT</h5>
          <p>{{cases.cbct + ($store.state.lang == 'ko' ? ' 건' : (cases.cbct > 1 ? ' cases' : ' case'))}}</p>
        </li>
      </ul>
    </div>
    <button class="close_btn" @click="$emit('close_my_info')">{{lang[$store.state.lang].MY_INFO_BUTTON_CLOSE}}</button>
  </div>
</template>

<script>
import lang from '@/lang'
import wsMixin from '@/wsMixin'
export default {
  name : 'MyInfoModal',
  mixins: [wsMixin],
  data(){
    return{
      lang,
      date : {
        year : new Date().getFullYear(),
        month : new Date().getMonth() + 1
      },
      cases : {
        panorama : 0,
        cbct : 0
      },
      months: ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
    }
  },
  methods : {
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      /*
      ** 157번 action(= 영상목록 조회)에 대한 응답이 수신되었을 때의 처리
      ** id = 3 해당 연, 월의 영상목록을 조회하여 의뢰를 몇 건 했는지 산출한다.
      */
      if(data.action == 157){
        // 응답이 성공이고, data.id = 3인경우에 대한 처리
        if(data.body[0].content.success && data.id == 3){
          // 수신된 영상목록의 길이만큼 반복문을 반복하여 영상의 종류가 px 인지 ct인지 구별하여 cases에 push한다.
          data.body[1].content.forEach((exam)=>{
            // 영상종류가 px인 경우에 대한 처리
            if(exam.type == 'PX') this.cases.panorama += 1;
            // 영상종류가 ct인 경우에 대한 처리
            else this.cases.cbct += 1;
          })
        }
      }
    }
  },
  mounted(){
    // status == 'completed' 인 영상목록을 요청한다. 
    let packet = this.$$build(157,3,[{
      contentType : 1,
      content: {
        filter: {
          dateFrom : `${this.date.year}-${this.date.month.toString().length==1?'0'+this.date.month.toString():this.date.month}-01`,
          dateTo: `${this.date.year}-${this.date.month.toString().length==1?'0'+this.date.month.toString():this.date.month}-${new Date(this.date.year,this.date.month,0).getDate()}`,
          status : 'completed'
        }
      }
    }])
    this.$socket.send(packet);
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
  .my_info_modal{
    position: absolute;
    bottom: 4px;
    left: 5px;
    width: 312px;
    height: 395px;
    background-color: #212125;
    border: 1px solid #A0A0A0;
    box-sizing: border-box;
    border-radius: 8px;
    z-index: 2;
  }
  .my_info_modal h3{
    padding: 30px 0 12px;
    color: #fff;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    text-align: center;
  }
  .my_info_modal>p{
    font-size: 16px;
    line-height: 24px;
    color: #DFDEE0;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
    text-align: center;
  }
  .btn_wrap{
    width: 212px;
    margin: 24px auto 0;
    display: flex;
    column-gap: 12px;
  }
  .btn_wrap button{
    width: 100%;
    height: 36px;
    box-sizing: border-box;
    color: #DFDEE0;
    background-color: #0e0e0e;
    border: 1px solid #545458;
    border-radius: 4px;
    font-size: 14px;
  }
  .my_info_modal button:active{
    transform: translateY(2px);
  }
  .bar{
    width: 240px;
    height: 1px;
    background: #2E2E33;
    margin: 12px auto 17px;
  }
  .request_history{
    width: 240px;
    margin: 0 auto;
  }
  .title{
    display: flex;
    justify-content: space-between;
    padding: 0 14px;
    font-size: 14px;
  }
  .title h4{
    color: #DFDEE0;;
    line-height: 22px;
    font-size: 14px;
  }
  .title div{
    color: #9192F6;
  }
  .case{
    padding: 18px 14px;
    margin-top: 6px;
    width: 100%;
    height: 90px;
    background-color: #1A1A1B;
    border-radius: 4px;
    box-sizing: border-box;
  }
  .case li{
    display: flex;
    justify-content: space-between;
    color: #DFDEE0;
    line-height: 24px;
  }
  .case li h5,
  .case li p {
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
  }
  .case li:first-child{
    margin-bottom: 6px;
  }
  .close_btn{
    display: block;
    width: 100px;
    height: 40px;
    margin: 24px auto 0;
    color: white;
    background-color: #0e0e0e;
    border: 1px solid #5510B5;
    border-radius: 4px;
    font-size: 16px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 700;
  }
  .close_btn:hover{
    background-color: #9560F5;
  }
</style>