<template>
  <div :style="{ height: bodyHeight }">
    <div id="login" class="login_background no-scrollbar">
      <div class="df_box login_box">
        <div class="tit clear">
          <h2 class="logo">
            <img
              src="@/assets/images/logo_without_rd.png"
              width="168"
              height="50"
            />
          </h2>
          <span class="bar">
            <img
              src="@/assets/images/login_bar.png"
              width="1"
              height="16"
              alt=""
            />
          </span>
          <p>{{lang[$store.state.lang].LOGIN_TITLE}}<!--치과영상 원격판독 시스템--></p>
        </div>
        <div id="login_box" class="txt">
          <ul>
            <li class="login_li login_id">
              <div class="form_cont">
                <input
                  id="loginId"
                  autocomplete="off"
                  placeholder=" "
                  required
                  v-model="email"
                  ref="emailInput"
                />
                <label for="loginId">{{lang[$store.state.lang].LOGIN_TXT_ENTER_ID}}<!--아이디를 입력하세요.--></label>
              </div>
            </li>
            <li class="login_li login_pw">
              <div class="form_cont">
                <input
                  type="password"
                  id="loginPw"
                  autocomplete="off"
                  placeholder=" "
                  required
                  v-model="passwd"
                  ref="passwdInput"
                  @keydown="_enterLogin"
                />
                <label for="loginPw">{{lang[$store.state.lang].LOGIN_TXT_ENTER_PASSWD}}<!--비밀번호를 입력하세요.--></label>
              </div>
            </li>
            <li class="login_btn">
              <button @click="tryLogin" id="login_button">{{lang[$store.state.lang].LOGIN_BTN_LOGIN}}<!--로그인--></button>
            </li>
            <li class="change_pw flex_between" id="register">
              <button @click="registerBoxVisibility = true">{{lang[$store.state.lang].LOGIN_BTN_SIGNUP}}<!--가입신청하기--></button>
              <button @click="recvTempPsw">{{lang[$store.state.lang].LOGIN_BTN_RESET_PSW}}<!--비밀번호 재설정하기--></button>
            </li>
          </ul>
        </div>
      </div>
      <RegisterModal
        v-if="registerBoxVisibility"
        @close="registerBoxVisibility = false"
      />
    </div>
    <footer id="footer">
      <h2 class="ft_logo">
        <a href="https://invisionlab.kr" target="_blank">
          <img
            src="@/assets/images/ft_logo.png"
            width="160"
            height="37"
            alt=""
          />
        </a>
      </h2>
      <ul class="clear">
        <!-- 추가될 여지가 있어서 주석처리함 -->
        
        <!-- <li>
          <a href="">자주 묻는 질문</a>
        </li>
        <li>
          <a href="">업데이트 소식</a>
        </li> -->
       
        <li class="ft_li3">
          <a href="https://invisionlab.kr/policy" target="_blank" 
            >{{lang[$store.state.lang].LOGIN_LINK_PRIVACY}}<!--개인정보 처리방침--></a
          >
        </li>
        <li>
          <a href="mailto:dhkim@invisionlab.kr"
            >{{lang[$store.state.lang].LOGIN_LINK_CONTACT}}<!--상담문의 dhkim@invisionlab.kr--></a
          >
        </li>
      </ul>

      <div style="float:right">
        <address>{{lang[$store.state.lang].LOGIN_TXT_COPYRIGHT}}<!--InVisionLab. All Rights Reserved.--></address>
        <div class="select_lang" @click="setLang">
          <span data-lang="en">EN</span>
          <span class="bar"></span>
          <span data-lang="ko">KO</span>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import '@/assets/css/main.css';
import wsMixin from '@/wsMixin';
import RegisterModal from '@/components/RegisterModal';
import lang from '@/lang';
import alert from '@/alert';

export default {
  name: "LoginPage",
  components: {
    RegisterModal,
  },
  data: function () {
    return {
      lang,
      bodyHeight: window.innerHeight - 80 + "px",
      registerBoxVisibility: false,
      email: '',
      passwd: '',
    };
  },
  mixins: [wsMixin],
  created: function() {
    window.addEventListener("resize", this.onResize);
  },
  destroyed: function() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    alert,
    onResize: function() {
      this.bodyHeight = (window.innerHeight - 80)+"px";
    },
    onRecv: function(data) {
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      // id = 0 인 11번 action(= 로그인)에 대한 응답이 수신된 경우에 대한 처리
      if( data.action == 11 ){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          if ( data.id == 0 ) {
            sessionStorage.setItem('id',this.email);
            sessionStorage.setItem('passwd',this.passwd);
            this.$store.state.socket.isLoggedIn = true;
          }
          
          // 로그인된 회원의 정보 중에서 signature 의 값이 있는 경우에 store.state.signature에 서명정보를 저장하여 다른 컴포넌트에서도 접근 가능하도록 한다.
          if(data.body[0].content.signature) this.$store.state.signature = data.body[0].content.signature;
          if(data.body[0].content.signature) this.$store.state.signature = data.body[0].content.signature;
          if(data.body[0].content.hospital.name) this.$store.state.hospitalInfo.name = data.body[0].content.hospital.name;
          if(data.body[0].content.hospital.addr1) this.$store.state.hospitalInfo.addr1 = data.body[0].content.hospital.addr1;
          if(data.body[0].content.hospital.addr2) this.$store.state.hospitalInfo.addr2 = data.body[0].content.hospital.addr2;
          if(data.body[0].content.hospital.ceo) this.$store.state.hospitalInfo.ceo = data.body[0].content.hospital.ceo;
          if(data.body[0].content.hospital.company_no) this.$store.state.hospitalInfo.company_no = data.body[0].content.hospital.company_no;
          if(data.body[0].content.hospital.phone) this.$store.state.hospitalInfo.contact = data.body[0].content.hospital.phone;
          if(data.body[0].content.hospital.taxinfo) this.$store.state.hospitalInfo.taxInfo = data.body[0].content.hospital.taxinfo;
          if(data.body[0].content.doctor.license_no) this.$store.state.doctorInfo.license = data.body[0].content.doctor.license_no;
          if(data.body[0].content.doctor.name) this.$store.state.doctorInfo.name = data.body[0].content.doctor.name;
          if(data.body[0].content.doctor.phone) this.$store.state.doctorInfo.phone = data.body[0].content.doctor.phone;
          if(data.body[0].content.doctor.email) this.$store.state.doctorInfo.email = data.body[0].content.doctor.email;
          if(process.env.VUE_APP_DEBUG) console.log('Login',data)
          this.$router.push('/main');
        }
        // 응답이 실패인 경우에 alert를 띄워 사용자에게 알리고, 패스워드 데이터를 초기화 하고, focus를 주어 사용자가 비밀번호를 다시 작성할 수 있도록 한다.
        else{
          this.alert('error',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_FAIL) // '로그인에 실패하셨습니다\n다시 시도해주세요'
          if( data.id == 0 ){
            this.passwd = "";
            this.$refs.passwdInput.focus();
          }
        }
      }
      // 67번 action(= 임시 비밀번호 발급)에 대한 응답이 수신된 경우에 대한 처리
      if(data.action == 67) {
        if(data.body[0].content.success) this.alert('default',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_TEMP_PWS_SENDED); // '임시 비밀번호가 발급되었습니다.'
        else this.alert('error',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_NOT_EXIST_EMAIL); // '존재하지 않는 이메일 입니다.'
      }
    },
    tryLogin: function() {
      if(this.email.length == 0 || !(this.email.includes('@'))) {
        this.$refs.emailInput.focus();
        if(this.email.length == 0) return this.alert('error',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_PLEASE_WRITE_EMAIL); // '이메일을 작성해 주세요.'
        if(!(this.email.includes('@'))) return this.alert('error',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_PLEASE_WRITE_VALID_EMAIL); // '이메일을 형식에 맞게 작성해 주세요.'
      }
      let userInfo = this.$$build(
        11,
        [
          {
            contentType: 1,
            content: {type:"RD",email:this.email,passwd:this.passwd},
          }
        ]
      );
      this.$socket.send(userInfo);
    },
    _enterLogin(ev){
      // keydown 이벤트가 발생했을 때 keyCode가 13(= 엔터키)인 경우 tryLogin()을 호출한다.
      if(ev.keyCode == 13) this.tryLogin();
    },
    setLang(ev){
      if(ev.target.dataset.lang == 'ko') this.$store.state.lang = 'ko';
      else this.$store.state.lang = 'en';
      sessionStorage.setItem('lang',this.$store.state.lang);
    },
    recvTempPsw(){
      if(this.email.length == 0 || !(this.email.includes('@'))) {
        this.$refs.emailInput.focus();
        if(this.email.length == 0) return this.alert('error',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_PLEASE_WRITE_EMAIL); // '이메일을 작성해 주세요.'
        if(!(this.email.includes('@'))) return this.alert('error',this.lang[this.$store.state.lang].LOGIN_ALERT_TXT_PLEASE_WRITE_VALID_EMAIL); // '이메일을 형식에 맞게 작성해 주세요.'
      }
      let packet = this.$$build(67,[{
        contentType: 1,
        content: {email: this.email}
      }]);
      this.$socket.send(packet);
    }
  },
  mounted(){
    this.$refs.emailInput.focus();
    if(!sessionStorage.getItem('lang')) sessionStorage.setItem('lang',this.$store.state.lang);
    if(sessionStorage.getItem('id')) this.$router.push('/main');
  },
}
</script>

<style scoped>
  .select_lang{
    font-size: 18px;
    color: white;
    margin-right: 40px;
    line-height: 80px;
  }
  .bar{
    display: inline-block;
    width: 1px;
    height: 10px;
    background: white;
    margin: 0 8px;
  }
  .select_lang span{
    cursor: pointer;
  }
  .select_lang span:nth-child(2){
    cursor: default;
  }
  .flex_between{
    display: flex;
    justify-content: space-between;
  }
</style>
