<template>
  <div class="change_private_info_modal">
    <div class="change_private_info_box">
      <button class="close_top" @click="$store.state.changePrivateInfoModal = false">
        <img src="@/assets/images/dise_pu_close.png" alt="close_btn">
      </button>
      <div class="title">
        <h2>{{lang[$store.state.lang].MY_INFO_TXT_EDIT_PRIVATE_INFO}}<!--개인정보 변경--></h2>
      </div>
      <div class="content_wrap">
        <ul class="tabs" @click="changeTabType">
          <li data-tab="doctor" :class="['tab', tabType == 'doctor' ? 'tab_active' : '']"><a data-tab="doctor">{{lang[$store.state.lang].EDIT_INFO_TAB_TXT_DOCTOR}}<!--의사정보--></a></li>
          <li data-tab="hospital" :class="['tab', tabType == 'hospital' ? 'tab_active' : '']"><a data-tab="hospital">{{lang[$store.state.lang].EDIT_INFO_TAB_TXT_HOSPITAL}}<!--병원정보--></a></li>
          <li data-tab="password" :class="['tab', tabType == 'password' ? 'tab_active' : '']"><a data-tab="password">{{lang[$store.state.lang].EDIT_INFO_TAB_TXT_PASSWORD}}<!--비밀번호 변경--></a></li>
          <li data-tab="auto_request" :class="['tab', tabType == 'auto_request' ? 'tab_active' : '']"><a data-tab="auto_request">{{lang[$store.state.lang].EDIT_INFO_TAB_TXT_SET_REQUEST}}<!--자동의뢰 설정--></a></li>
        </ul>
        <div class="content_area">
          <div class="txt_wrap flex_col" v-if="tabType == 'doctor'">
            <div class="color_white" v-html="lang[$store.state.lang].EDIT_INFO_DOCTOR_TAB_TOP_TXT"></div> <!--성함과 아이디(이메일)은 변경하실 수 없습니다. 변경을 원하시는 경우 관리자에게 문의주세요.판독소견서에 아래 정보로 판독자 정보가 입력됩니다. -->
            <div class="flex_col">
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_DOCTOR_NAME}}<!--의사명--></div>
                <div class="txt_line">
                  <img src="@/assets/images/id.png" alt="name">
                  <p>{{$store.state.doctorInfo.name}}</p>
                </div>
              </div>
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_DOCTOR_EMAIL}}<!--아이디(이메일)--></div>
                <div class="txt_line">
                  <img src="@/assets/images/id.png" alt="id">
                  <p>{{$store.state.doctorInfo.email}}</p>
                </div>
              </div>
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_DOCTOR_PHONE}}<!--휴대폰 번호--></div>
                <div class="relative">
                  <div :class="['txt_line', 'bg_bk_transparent', alertStates.doctorPhone?'alert_border':'']">
                    <img src="@/assets/images/phone.png" alt="phone">
                    <div class="input_wrap">
                      <input type="text" v-model="doctorInfo.phone" placeholder="010-0000-0000"/>
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.doctorPhone">{{lang[$store.state.lang].SIGNUP_TXT_PHONE_NOTICE}}<!--유효한 번호를 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row" v-if="$store.state.lang == 'ko'">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_DOCTOR_LICENSE_NO}}<!--의사 면허번호--></div>
                <div class="relative">
                  <div :class="['txt_line', 'bg_bk_transparent', alertStates.doctorLicense?'alert_border':'']">
                    <img src="@/assets/images/license.png" alt="license_no">
                    <div class="input_wrap">
                      <input type="text" v-model="doctorInfo.license_no" placeholder="00000"/>
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.doctorLicense">{{lang[$store.state.lang].SIGNUP_TXT_PHONE_NOTICE}}<!--유효한 번호를 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row">
                <div class="sign_row_txt">{{lang[$store.state.lang].EDIT_INFO_DOCTOR_SIGN}}<!--판독자 서명--></div>
                <div class="sign_upload_wrap">
                  <div class="img_txt_col">
                    <div style="position:relative"  @mouseenter="showRemoveBtn" @mouseleave="hideRemoveBtn">
                      <span @click="removeSignImg" v-if="signImgUrl && isVisibleRemoveBtn" class="remove_sign_btn"><img src="@/assets/images/print_pu_close.png" alt="remove_sign" width="15px" height="15px"></span>
                      <input :disabled="signImgUrl.length > 0" id="sign_img_file" ref="signImg" type="file" accept="image/png, image/jpeg, .pdf" @input="getUrlToUpload" style="display:none">
                      <label for="sign_img_file" class="img_box">
                        <span v-if="!signImgUrl" class="cross_bar_ver"></span>
                        <span v-if="!signImgUrl" class="cross_bar_hor"></span>
                        <img v-if="signImgUrl" :src="signImgUrl" alt="sign_img">
                      </label>
                    </div>
                  </div>
                  <div class="sign_info_txt">
                    <p>{{lang[$store.state.lang].EDIT_INFO_DOCTOR_SIGN_UPLOAD_TXT}}<!--서명이미지 파일을 등록해주세요.--></p>
                    <p>{{lang[$store.state.lang].EDIT_INFO_DOCTOR_SIGN_UPLOAD_REQUIREMENT}}<!--이미지 비율 1대1, 해상도 100px 이상을 권장합니다--></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="txt_wrap flex_col" v-if="tabType == 'hospital'">
            <div class="color_white" v-html="lang[$store.state.lang].EDIT_INFO_HOSPITAL_TAB_TOP_TXT"></div><!--병원 정보는 세금계산서 발행을 위해 필요하니, 정확히 입력해주세요.세금계산서용 이메일은 입력하지 않으시면 아이디용 이메일로 입력됩니다.-->
            <div class="flex_col">
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_NAME}}<!--병원명--></div>
                <div class="relative">
                  <div :class="['txt_line bg_bk_transparent', alertStates.hospitalName ? 'alert_border': '']">
                    <img src="@/assets/images/address.png" alt="hospital_name">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.name" :placeholder="lang[$store.state.lang].EDIT_INFO_HOSPITAL_NAME"/><!--병원명-->
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.hospitalName">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_NAME_ALERT_TXT}}<!--병원명을 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row" v-if="$store.state.lang == 'ko'">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_CEO}}<!--대표자명--></div>
                <div class="relative">
                  <div :class="['txt_line bg_bk_transparent', alertStates.hospitalCeo ? 'alert_border': '']">
                    <img src="@/assets/images/id.png" alt="representative">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.ceo" :placeholder="lang[$store.state.lang].EDIT_INFO_HOSPITAL_CEO_TXT_PLACEHOLDER"/><!--홍길동-->
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.hospitalCeo">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_CEO_ALERT_TXT}}<!--대표자명을 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_BUSINESS_NO}}<!--사업자등록번호--></div>
                <div class="relative">
                  <div :class="['txt_line bg_bk_transparent', alertStates.hospitalBusinessNo ? 'alert_border': '']">
                    <img src="@/assets/images/license.png" alt="business_no">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.business_no" placeholder="000-00-00000"/>
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.hospitalBusinessNo">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_BUSINESS_NO_ALERT_TXT}}<!--사업자등록번호를 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_ADDRESS}}<!--병원 주소--></div>
                <div class="relative">
                  <div :class="['txt_line bg_bk_transparent', alertStates.hospitalAddr ? 'alert_border': '']">
                    <img src="@/assets/images/address.png" alt="hospital_address">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.addr" :placeholder="lang[$store.state.lang].EDIT_INFO_HOSPITAL_ADDRESS_TXT_PLACEHOLDER"/><!--주소-->
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.hospitalAddr">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_ADDRESS_ALERT_TXT}}<!--주소를 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row" v-if="$store.state.lang != 'ko'">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_ADDRESS}}<!--병원 주소--></div>
                <div class="relative">
                  <div class="txt_line bg_bk_transparent">
                    <img src="@/assets/images/address.png" alt="hospital_address">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.addrCity" :placeholder="lang[$store.state.lang].EDIT_INFO_HOSPITAL_ADDRESS_TXT_PLACEHOLDER"/><!--주소-->
                    </div>
                  </div>
                </div>
              </div>
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_CONTACT}}<!--병원 전화번호--></div>
                <div class="relative">
                  <div :class="['txt_line bg_bk_transparent', alertStates.hospitalPhone ? 'alert_border': '']">
                    <img src="@/assets/images/call.png" alt="hospital_contact">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.contact" placeholder="02-000-0000"/>
                    </div>
                  </div>
                  <div class="alert_txt" v-if="alertStates.hospitalPhone">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_CONTACT_ALERT_TXT}}<!--유효한 번호를 입력하세요.--></div>
                </div>
              </div>
              <div class="txt_row">
                <div class="title">{{lang[$store.state.lang].EDIT_INFO_HOSPITAL_EMAIL_FOR_TAX}}<!--계산서용 이메일--></div>
                <div class="relative">
                  <div class="txt_line bg_bk_transparent">
                    <img src="@/assets/images/email.png" alt="email_for_tax">
                    <div class="input_wrap">
                      <input type="text" v-model="hospitalInfo.taxInfo" placeholder="dentist@hometax.go.kr"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="txt_wrap" v-if="tabType == 'password'">
            <div class="txt_row">
              <div class="title">{{lang[$store.state.lang].EDIT_INFO_PASSWORD_TXT}}<!--비밀번호--></div>
              <div class="relative">
                <div :class="['txt_line', 'bg_bk_transparent', alertStates.newPasswd ? 'alert_border' : '']">
                  <img src="@/assets/images/password.png" alt="password">
                  <div class="input_wrap">
                    <input type="password" :placeholder="lang[$store.state.lang].EDIT_INFO_PASSWORD_TXT_PLACEHOLDER" v-model="doctorInfo.passwd"/>
                  </div>
                </div>
                <div class="alert_txt" v-if="alertStates.newPasswd">{{lang[$store.state.lang].EDIT_INFO_PASSWORD_ALERT_TXT}}<!--8자 이상 필수, 영문 대소문자/숫자/특수문자로 조합 가능--></div>
              </div>
            </div>
            <div class="txt_row">
              <div class="title">{{lang[$store.state.lang].EDIT_INFO_PASSWORD_TXT_CONFIRM}}<!--비밀번호 확인--></div>
              <div class="relative">
                <div :class="['txt_line', 'bg_bk_transparent', alertStates.reNewPasswd ? 'alert_border' : '']">
                  <img src="@/assets/images/password.png" alt="password">
                  <div class="input_wrap">
                    <input type="password" :placeholder="lang[$store.state.lang].EDIT_INFO_PASSWORD_TXT_PLACEHOLDER_AGAIN" v-model="doctorInfo.rePasswd"/>
                  </div>
                </div>
                <div class="alert_txt" v-if="alertStates.reNewPasswd">{{lang[$store.state.lang].EDIT_INFO_PASSWORD_ALERT_RENEW}}<!--비밀번호가 일치하지 않습니다.--></div>
              </div>
            </div>
          </div>
          <div class="txt_wrap" v-if="tabType == 'auto_request'">
            <div class="row_wrap">
              <h2 class="row_title">{{lang[$store.state.lang].EDIT_INFO_TXT_POLICY_AUTO_REQ_PX}}<!--파노라마 자동의뢰--></h2>
              <div class="select_button_wrap" @click="changePxRqStatus">
                <div class="row">
                  <div class="check_item">
                    <span data-item="new" :class="[pxRequestStatus=='new'?'checked':'default']"></span>
                    <input type="checkbox" name="new" id="px_new">
                    <label for="px_new">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_NEW_PATIENT}}<!--신규 환자--></label>
                  </div>
                  <div class="check_item">
                    <span data-item="halfYear" :class="[pxRequestStatus=='halfYear'?'checked':'default']"></span>
                    <input type="checkbox" name="halfYear" id="px_halfYear">
                    <label for="px_halfYear">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_POLICY_HALF_YEAR}}<!--6개월 내 첫방문--></label>
                  </div>
                  <div class="check_item">
                    <span data-item="year" :class="[pxRequestStatus=='year'?'checked':'default']"></span>
                    <input type="checkbox" name="year" id="px_year">
                    <label for="px_year">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_POLICY_A_YEAR}}<!--12개월 내 첫방문--></label>
                  </div>
                  <div class="check_item">
                    <span data-item="reject" :class="[pxRequestStatus=='reject'?'checked':'default']"></span>
                    <input type="checkbox" name="reject" id="px_reject">
                    <label for="px_reject">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_REJECT}}<!--자동의뢰 하지 않음--></label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row_wrap">
              <h2 class="row_title">{{lang[$store.state.lang].EDIT_INFO_TXT_POLICY_AUTO_REQ_CT}}<!--CBCT 자동의뢰--></h2>
              <div class="select_button_wrap" @click="changeCtRqStatus">
                <div class="row">
                  <div class="check_item">
                    <span data-item="new" :class="[ctRequestStatus=='new'?'checked':'default']"></span>
                    <input type="checkbox" name="new" id="new">
                    <label for="new">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_NEW_PATIENT}}<!--신규 환자--></label>
                  </div>
                  <div class="check_item">
                    <span data-item="halfYear" :class="[ctRequestStatus=='halfYear'?'checked':'default']"></span>
                    <input type="checkbox" name="halfYear" id="halfYear">
                    <label for="halfYear">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_POLICY_HALF_YEAR}}<!--6개월 내 첫방문--></label>
                  </div>
                  <div class="check_item">
                    <span data-item="year" :class="[ctRequestStatus=='year'?'checked':'default']"></span>
                    <input type="checkbox" name="year" id="year">
                    <label for="year">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_POLICY_A_YEAR}}<!--12개월 내 첫방문--></label>
                  </div>
                  <div class="check_item">
                    <span data-item="reject" :class="[ctRequestStatus=='reject'?'checked':'default']"></span>
                    <input type="checkbox" name="reject" id="reject">
                    <label for="reject">{{lang[$store.state.lang].EDIT_INFO_TXT_REQ_REJECT}}<!--자동의뢰 하지 않음--></label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button_wrap">
        <button class="basic_btn black_btn" @click="$emit('close_change_privateInfo_modal')">{{lang[$store.state.lang].EDIT_INFO_BUTTON_CLOSE}}<!--닫기--></button>
        <button class="basic_btn purple_btn" @click="save">{{lang[$store.state.lang].EDIT_INFO_BUTTON_SAVE}}<!--변경저장--></button>
      </div>
    </div>
  </div>
</template>

<script>
import { AsYouType } from 'libphonenumber-js'
import wsMixin from '@/wsMixin';
import lang from '@/lang'
import alert from '@/alert'

export default {
  name : 'ChangePrivateInfoModal',
  mixins: [wsMixin],
  data(){
    return{
      lang,
      doctorInfo:{
        passwd:'',
        rePasswd:'',
        phone: '',
        license_no:'',
      },
      hospitalInfo:{
        name: '',
        ceo:'',
        business_no: '',
        addr: '',
        addrCity: '',
        contact: '',
        taxInfo: ''
      },
      alertStates: {
        doctorPhone: false,
        doctorLicense: false,
        hospitalName: false,
        hospitalCeo: false,
        hospitalBusinessNo: false,
        hospitalAddr: false,
        hospitalPhone: false,
        newPasswd: false,
        reNewPasswd: false,   
      },
      signImgUrl:'',
      tabType: 'doctor',
      pxRequestStatus: 'reject',
      ctRequestStatus: 'reject',
      isVisibleRemoveBtn: false,
      reqBody: []
    }
  },
  methods : {
    alert,
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      /**
       * 105번 action(= 회원정보 수정)에 대한 응답이 수신된 경우에 대한 처리
       * id = 0 의사정보 변경
       * id = 1 병원정보 변경
       * id = 2 비밀번호 변경
       */
      if(data.action == 15) {
        if(data.body[0].content.success) {
          if( data.id == 0 ) {
            if( this.reqBody[0].content.doctor.phone ) this.$store.state.doctorInfo.phone = this.reqBody[0].content.doctor.phone; 
            if( this.reqBody[0].content.doctor.license_no ) this.$store.state.doctorInfo.license = this.reqBody[0].content.doctor.license_no; 
            if( this.reqBody[0].content.doctor.signature ) this.$store.state.signature = this.reqBody[0].content.doctor.signature; 
            this.alert('success',this.lang[this.$store.state.lang].EDIT_INFO_SUCCESS_CHANGE_DOCTOR); // 의사정보를 변경하는것에 성공하였습니다.
          }
          if( data.id == 1 ){
            if( this.reqBody[0].content.hospital.name ) this.$store.state.hospitalInfo.name = this.reqBody[0].content.hospital.name; 
            if( this.reqBody[0].content.hospital.ceo ) this.$store.state.hospitalInfo.ceo = this.reqBody[0].content.hospital.ceo; 
            if( this.reqBody[0].content.hospital.company_no ) this.$store.state.hospitalInfo.company_no = this.reqBody[0].content.hospital.company_no; 
            if( this.reqBody[0].content.hospital.addr1 ) this.$store.state.hospitalInfo.addr1 = this.reqBody[0].content.hospital.addr1; 
            if( this.reqBody[0].content.hospital.addr2 ) this.$store.state.hospitalInfo.addr2 = this.reqBody[0].content.hospital.addr2; 
            if( this.reqBody[0].content.hospital.phone ) this.$store.state.hospitalInfo.phone = this.reqBody[0].content.hospital.phone; 
            if( this.reqBody[0].content.hospital.taxinfo ) this.$store.state.hospitalInfo.taxinfo = this.reqBody[0].content.hospital.taxinfo; 
            this.alert('success',this.lang[this.$store.state.lang].EDIT_INFO_SUCCESS_CHANGE_HOSPITAL); // 병원정보를 변경하는것에 성공하였습니다.
          }
          if( data.id == 2 ){
            sessionStorage.setItem('passwd',this.doctorInfo.passwd);
            this.doctorInfo = {
              ...this.doctorInfo,
              passwd:'',
              rePasswd:'',
            };
            this.alert('success',this.lang[this.$store.state.lang].EDIT_INFO_SUCCESS_CHANGE_PASSWD); // 비밀번호를 변경하는것에 성공하였습니다.
          }
        }else {
          if( data.id == 0) this.alert('error',this.lang[this.$store.state.lang].EDIT_INFO_FAIL_CHANGE_DOCTOR); // 의사정보를 변경하는것에 실패하였습니다.
          if( data.id == 1) this.alert('error',this.lang[this.$store.state.lang].EDIT_INFO_FAIL_CHANGE_HOSPITAL); // 병원정보를 변경하는것에 실패하였습니다.
          if( data.id == 2 ) this.alert('error',this.lang[this.$store.state.lang].EDIT_INFO_FAIL_CHANGE_PASSWD); // 비밀번호를 변경하는것에 실패하였습니다.
        }
      }
    },
    changeTabType(ev){
      let clicked = ev.target.dataset.tab;
      if(!clicked) return;
      // click 이벤트가 발생한 target의 데이터에 따라서 어떤 탭을 click했는지 tabType 데이터를 변경한다. 
      this.tabType = clicked;
    },
    changePxRqStatus(ev){
      // pxRequestStatus = new | halfYear | year | reject 
      if(ev.target.name) this.pxRequestStatus = ev.target.name;
      else if(ev.target.dataset.item) this.pxRequestStatus = ev.target.dataset.item;
      else return;
    },
    changeCtRqStatus(ev){
      // ctRequestStatus = new | halfYear | year | reject 
      if(ev.target.name) this.ctRequestStatus = ev.target.name;
      else if(ev.target.dataset.item) this.ctRequestStatus = ev.target.dataset.item;
      else return;
    },
    getUrlToUpload(ev){
      let fr = new FileReader();
      fr.onload = (e) => {
        this.signImgUrl = e.target.result;
      };
      fr.readAsDataURL(ev.target.files[0]);
    },
    removeSignImg(){
      this.signImgUrl = '';
      this.$refs.signImg.value = '';
    },
    showRemoveBtn(){
      this.isVisibleRemoveBtn = true;
    },
    hideRemoveBtn(){
      this.isVisibleRemoveBtn = false;
    },
    updateAlertStates(property, bool) {
      this.alertStates = {
        ...this.alertStates,
        [property]: bool
      };
    },
    changePasswd(){
      let isInKorean =  /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
      if( this.doctorInfo.passwd != this.doctorInfo.rePasswd ) this.updateAlertStates('reNewPasswd',true); 
      else this.updateAlertStates('reNewPasswd',false); 

      if(!(!isInKorean.test(this.doctorInfo.passwd) && this.doctorInfo.passwd.length>=8)) this.updateAlertStates('newPasswd',true); 
      else this.updateAlertStates('newPasswd',false);

      if( !this.alertStates.newPasswd && !this.alertStates.reNewPasswd ) {
        this.reqBody = [{
          contentType: 1,
          content: {
            passwd: sessionStorage.getItem('passwd'),
            doctor: {
              passwd: this.doctorInfo.passwd,
            }
          }
        }];

        let packet = this.$$build( 15, 2, this.reqBody );
        this.$socket.send(packet);
      }
    },
    changeDoctorInfo(lang){
      let couldSendAction = false;

      this.reqBody = [{
        contentType: 1,
        content: {
          passwd: sessionStorage.getItem('passwd'),
          doctor: { }
        }
      }];
      
      if(this.doctorInfo.phone.trim().length <= 0) this.updateAlertStates('doctorPhone',true);
      else {
        this.updateAlertStates('doctorPhone',false);
        if(this.doctorInfo.phone.trim().replaceAll(' ','').replaceAll('-','') != this.$store.state.doctorInfo.phone.trim().replaceAll(' ','').replaceAll('-','')){
          this.reqBody[0].content.doctor.phone = this.doctorInfo.phone;
        }
      }

      if(this.$store.state.signature != this.signImgUrl) this.reqBody[0].content.doctor.signature = this.signImgUrl;

      if( lang == 'ko') {
        if(this.doctorInfo.license_no.trim().length != 5 ) this.updateAlertStates('doctorLicense',true); 
        else {
          this.updateAlertStates('doctorLicense',false); 
          if( this.doctorInfo.license_no != this.$store.state.doctorInfo.license ) {
            this.reqBody[0].content.doctor.license_no = this.doctorInfo.license_no;
          }
        }

        if( !this.alertStates.doctorPhone && !this.alertStates.doctorLicense) couldSendAction = true;
      }

      if( lang == 'en' && !this.alertStates.doctorPhone ) couldSendAction = true;

      if( couldSendAction ) {
        let packet = this.$$build(15,0,this.reqBody);
        this.$socket.send(packet);
      }
    },
    changeHospitalInfo(lang) {
      this.reqBody = [{
        contentType: 1,
        content: {
          passwd: sessionStorage.getItem('passwd'),
          hospital: { }
        }
      }];

      let couldSendAction = false;
      let hospitalInfo = this.$store.state.hospitalInfo;

      if( this.hospitalInfo.name.trim().length < 1 ) {
        this.updateAlertStates('hospitalName',true); 
      }else {
        this.updateAlertStates('hospitalName',false); 
        if( hospitalInfo.name.trim() != this.hospitalInfo.name.trim()) this.reqBody[0].content.hospital.name = this.hospitalInfo.name;
      }

      if(this.hospitalInfo.contact.trim().replaceAll(' ','').replaceAll('-','').length < 9) {
        this.updateAlertStates('hospitalPhone',true);  
      }else {
        this.updateAlertStates('hospitalPhone',false); 
        if( hospitalInfo.contact.trim() != this.hospitalInfo.contact.trim() ) this.reqBody[0].content.hospital.phone = this.hospitalInfo.contact;
      }

      if( hospitalInfo.company_no.trim() != this.hospitalInfo.business_no.trim() ) {
        if( lang != 'ko') this.reqBody[0].content.hospital.company_no = this.hospitalInfo.business_no;
        else {
          if( this.hospitalInfo.business_no.trim().length < 12) this.updateAlertStates('hospitalBusinessNo',true);  
          else {
            this.updateAlertStates('hospitalBusinessNo',false);  
            this.reqBody[0].content.hospital.company_no = this.hospitalInfo.business_no;
          }
        }
      }

      if( hospitalInfo.addr1.trim() != this.hospitalInfo.addr.trim() ) {
        if( lang != 'ko' ) this.reqBody[0].content.hospital.addr1 = this.hospitalInfo.addr;
        else {
          if( !this.hospitalInfo.addr.trim() ) this.updateAlertStates('hospitalAddr',true); 
          else {
            this.updateAlertStates('hospitalAddr',false);
            this.reqBody[0].content.hospital.addr1 = this.hospitalInfo.addr;
          }
        }
      }

      if( hospitalInfo.taxInfo.trim() != this.hospitalInfo.taxInfo.trim() ) this.reqBody[0].content.hospital.taxinfo = this.hospitalInfo.taxInfo;

      if( lang == 'ko' ) {
        if( this.hospitalInfo.ceo.trim().length == 0 ) this.updateAlertStates('hospitalCeo',true);  
        else {
          this.updateAlertStates('hospitalCeo',false);  
          if( hospitalInfo.ceo.trim() != this.hospitalInfo.ceo.trim() ) this.reqBody[0].content.hospital.ceo = this.hospitalInfo.ceo;
        }
        
        if( !this.alertStates.hospitalName && !this.alertStates.hospitalCeo &&!this.alertStates.hospitalBusinessNo &&!this.alertStates.hospitalAddr &&!this.alertStates.hospitalPhone ) couldSendAction = true;

      }else {
        if( hospitalInfo.addr2.trim() != this.hospitalInfo.addrCity.trim() ) this.reqBody[0].content.hospital.addr2 = this.hospitalInfo.addrCity;
        if( !this.alertStates.hospitalName && !this.alertStates.doctorPhone ) couldSendAction = true;
      }

      if( couldSendAction ) {
        let packet = this.$$build( 15, 1, this.reqBody );
        this.$socket.send( packet );
      }
    },
    save(){
      if(this.tabType == 'password') this.changePasswd();
      if(this.tabType == 'doctor') this.changeDoctorInfo(this.$store.state.lang);
      if(this.tabType == 'hospital') this.changeHospitalInfo(this.$store.state.lang);
    },
  },
  watch: {
    tabType(tab) {
      for(let key in this.alertStates) {
        this.alertStates[key] = false;
      }
      
      if(tab == 'doctor') {
        if(this.$store.state.doctorInfo.license) this.doctorInfo.license_no = this.$store.state.doctorInfo.license;
        if(this.$store.state.doctorInfo.phone) this.doctorInfo.phone = this.$store.state.doctorInfo.phone;
        if(this.$store.state.signature) this.signImgUrl = this.$store.state.signature;
      } else if(tab == 'hospital') {
        if(this.$store.state.hospitalInfo.name) this.hospitalInfo.name = this.$store.state.hospitalInfo.name;
        if(this.$store.state.hospitalInfo.ceo) this.hospitalInfo.ceo = this.$store.state.hospitalInfo.ceo;
        if(this.$store.state.hospitalInfo.company_no) this.hospitalInfo.business_no = this.$store.state.hospitalInfo.company_no;
        if(this.$store.state.hospitalInfo.addr1) this.hospitalInfo.addr = this.$store.state.hospitalInfo.addr1;
        if(this.$store.state.hospitalInfo.addr2) this.hospitalInfo.addrCity = this.$store.state.hospitalInfo.addr2;
        if(this.$store.state.hospitalInfo.contact) this.hospitalInfo.contact = this.$store.state.hospitalInfo.contact;
        if(this.$store.state.hospitalInfo.taxInfo) this.hospitalInfo.taxInfo = this.$store.state.hospitalInfo.taxInfo;
      } else if(tab == 'passwd') {
        this.doctorInfo.passwd = '';
        this.doctorInfo.rePasswd = '';
      }
    },
    'doctorInfo.phone'(newVal){
      if(newVal.length <= 1) return;
      const converted = new AsYouType('KR').input(newVal);
      this.doctorInfo.phone = converted;
    },
    'hospitalInfo.contact'(newVal){
      if(newVal.length <= 1) return;
      const converted = new AsYouType('KR').input(newVal);
      this.hospitalInfo.contact = converted;
    },
    'hospitalInfo.business_no'(newVal,prevVal) {
      let length = newVal.replaceAll('-','').length;
      if(length > 10) return this.hospitalInfo.business_no = prevVal;
      if(length > 3 && this.hospitalInfo.business_no[3] != '-') {
        let splitted = this.hospitalInfo.business_no.split('');
        splitted.splice(3,0,'-');
        this.hospitalInfo.business_no = splitted.join('')
      }
      if(length >5 && this.hospitalInfo.business_no[6] != '-'){
        let splitted = this.hospitalInfo.business_no.split('');
        splitted.splice(6,0,'-');
        this.hospitalInfo.business_no = splitted.join('')
      }
      
    }
  },
  mounted(){
    if(this.$store.state.doctorInfo.license) this.doctorInfo.license_no = this.$store.state.doctorInfo.license;
    if(this.$store.state.doctorInfo.phone) this.doctorInfo.phone = this.$store.state.doctorInfo.phone;
    if(this.$store.state.signature) this.signImgUrl = this.$store.state.signature;
  }
}
</script>

<style scoped>
  .change_private_info_modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
  .change_private_info_box{
    position: relative;
    width: 40%;
    width: 643px;
    height: 915px;
    min-height: 700px;
    border: 1px solid #2E2E33;
    box-sizing: border-box;
    border-radius: 12px;
    background: #1A1A1B;
    display: grid;
    grid-template-rows: auto 1fr auto;
    padding: 33px 55px 20px 56px;
    grid-row-gap: 30px;
  }
  .close_top{
    width: 24px;
    height: 24px;
    position: absolute;
    top: 40px;
    right: 48px;
  }
  .title h2{
    color: #fff;
    font-size: 24px;
    font-family: "Noto Sans KR", sans-serif;
    font-weight: 500;
  }
  .txt_wrap{
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 27px;
  }
  .txt_line{
    display: grid;
    height: 38px;
    border: 1px solid rgba(223,222,224,0.1);
    box-sizing: border-box;
    grid-template-columns: 80px 1fr;
    place-items: center left;
  }
  .txt_line img{
    justify-self: center;
  }
  .txt_row{
    display: flex;
    flex-direction: column;
    gap: 6px;
  }
  .txt_row>.title{
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: white;
  }
  .txt_row>div:last-child{
    flex: 3.6;
  }
  .sign_row_txt{
    margin-top: 10px;
    flex: 1;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    color: white;
  }
  .bg_bk_transparent{
    background: rgba(132,132,136,0.25);
  }
  .txt_line p{
    color: white;
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }
  .input_wrap{
    position: relative;
    width: 100%;
    height: 100%;
    font-size: 14px;
  }
  .input_wrap input{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    box-sizing: border-box;
    color: #fff;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    letter-spacing: 1px;
  }
  .input_wrap input::-webkit-input-placeholder{
    color: #B7B7B7;
    font-weight: 400;
    opacity: 1;
    transition: opacity 0.3s;
    font-size: 14px;
  }
  .input_wrap input:focus::-webkit-input-placeholder {
    opacity: 0;
  }
  .button_wrap{
    display: grid;
    grid-template-columns: 125px 125px;
    justify-content: center;
    height: 40px;
    column-gap: 10px;
  }
  button{
    width: 100%;
    height: 100%;
  }
  .basic_btn{
    background-color: #0e0e0e;
  }
  .black_btn{
    border: 1px solid #545458;
    box-sizing: border-box;
    color: #DFDEE0;
    font-size: 14px;
  }
  .black_btn:hover{
    color: white;
  }
  .purple_btn{
    color: white;
    box-sizing: border-box;
    border: 1px solid #5510B5;
    font-weight: 700;
    font-size: 16px;
  }
  .purple_btn:hover{
    background: #9560F5;
  }
  .content_wrap{
    display: flex;
    flex-direction: column;
  }
  .content_area{
    padding-top: 30px;
  }
  .tabs{
    height: 42px;
    border-bottom: 1px solid rgba(201, 197, 197, 0.831);
  }
  .tabs li{
    display: inline-block;
    height: 40px;
    padding: 0 10px;
    line-height: 40px;
    color: white;
    font-size: 15px;
  }
  .tabs:first-child{
    border-left: 1px solid #2E2E33;
  }
  .tab{
    border: 1px solid #2E2E33;
    cursor: pointer;
    border-left: none;
    border-bottom: none;
  }
  .tab_active{
    border: 1px solid white;
    border-bottom: 1px solid #1A1A1B;
    border-top-width: 2px;
  }
  .row_title{
    margin-bottom: 20px;
    color: white;
    font-size: 16px;
  }
  .select_button_wrap{
    padding-left: 7px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .select_button_wrap button:hover{
    background: #9560F5;
  }
  .select_button_wrap button{
    border: 1px solid #2E2E33;
    color: white;
  }
  .select_button_wrap button:nth-child(2){
    border-left: none;
    border-right: none;
  }
  .row_wrap{
    padding-bottom: 20px;
    border-bottom: 1px solid #403F45;
  }
  .select_btn_active{
    background: #9560F5;
  }
  .person_img{
    background-image: url('@/assets/images/id.png');
  }
  .passwd_img{
    background-image:url('@/assets/images/password.png')
  }
  .call_img{
    background-image: url('@/assets/images/call.png');
  }
  .phone_img{
    background-image: url('@/assets/images/phone.png');
  }
  input[type="checkbox"] {
    display: none;
  }
  .checked{
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('@/assets/images/checkOn.png');
  }
  .default{
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url('@/assets/images/checkOff.png');
  }
  label{
    color: white;
    font-size: 16px;
  }
  .check_item{
    display: flex;
    align-items: center;
    gap: 13px;
  }
  .row{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .license_upload_btn{
    min-width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #A45CFE;
    border: 1px solid #FFFFFF;
    color: white;
    box-sizing: border-box;
    font-size: 14px;
    cursor: pointer;
  }
  .file_upload_box{
    display: grid;
    grid-template-columns: 1fr auto;
  }
  .file_name_txt{
    width: 100%;
    height: 100%;
    padding-left: 28px;
    display: flex;
    align-items: center;
    color: white;
    font-size: 16px;
    box-sizing: border-box;
  }
  .sign_upload_wrap{
    display: flex;
  }
  .img_box{
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    background: #343436;
    border: 1px solid #4a494b;
  }
  .img_box>img{
    position: absolute;
    top: 5%;
    left: 5%;
    width: 90%;
    height: 90%;
  }
  .sign_info_txt{
    color: white;
    font-size: 11px;
    padding-left: 30px;
    padding-top: 13px;
    box-sizing: border-box;
  }
  .cross_bar_hor,
  .cross_bar_ver{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: #dfdfe1;
    cursor: pointer;
  }
  .cross_bar_hor{
    width: 20px;
    height: 4px;
  }
  .cross_bar_ver{
    width: 4px;
    height: 20px;
  }
  .sign_row_title{
    align-items: flex-start;
  }
  .txt_no_license{
    font-size: 14px;
    color: #848488;
    height: 38px;
  }
  .color_white{
    color: white;
  }
  .img_txt_col{
    display: flex;
    flex-direction: column;
    gap: 3px;
  }
  .remove_sign_btn{
    position: absolute;
    top: 5%;
    right: 5%;
    cursor: pointer;
    z-index: 1;
  }
  .flex_col{
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  .relative{
    position: relative;
  }
  .alert_txt{
    position: absolute;
    left: 20px;
    color: #ba4c57;
    font-size: 11px;
  }
  .alert_border{
    border-color: rgb(186, 76, 87);
  }
</style>