export default {
  "LOGIN_TITLE": "치과영상 원격판독 시스템",
  "LOGIN_TXT_ENTER_ID": "example@example.com",
  "LOGIN_TXT_ENTER_PASSWD": "비밀번호를 입력하세요.",
  "LOGIN_BTN_LOGIN": "로 그 인",
  "LOGIN_BTN_SIGNUP": "가입 신청하기",
  "LOGIN_BTN_RESET_PSW": "비밀번호 재설정하기",
  "LOGIN_ALERT_TXT_FAIL": "로그인에 실패하셨습니다\n다시 시도해주세요",
  "LOGIN_ALERT_TXT_TEMP_PWS_SENDED": "임시 비밀번호가 발급되었습니다.",
  "LOGIN_ALERT_TXT_NOT_EXIST_EMAIL": "존재하지 않는 이메일 입니다.",
  "LOGIN_ALERT_TXT_PLEASE_WRITE_EMAIL": "이메일을 작성해 주세요.",
  "LOGIN_ALERT_TXT_PLEASE_WRITE_VALID_EMAIL": "이메일을 형식에 맞게 작성해 주세요.",
  "LOGIN_LINK_PRIVACY": "개인정보 처리방침",
  "LOGIN_LINK_CONTACT": "상담문의 dhkim@invisionlab.kr",
  "LOGIN_TXT_COPYRIGHT": "© InVisionLab. All Rights Reserved.",

  "MENU_REQUEST": "영상조회",
  "MENU_REQUEST_MULTIPLE": "여러장 판독의뢰",
  "MENU_PRINT_MULTIPLE": "판독소견서",
  "MENU_MANUAL": "사용자 매뉴얼",
  "MENU_HISTORY": "판독의뢰 통계",
  "MENU_MYINFO": "내 정보",
  "MENU_LOGOUT": "로그아웃",
  "MENU_ALARM_HISTORY": "알림목록",
  "MENU_SET_LANG": "언어 변경",

  "MODAL_TITLE_PRINT_MULTIPLE": "판독소견서 다운로드",
  "MODAL_TITLE_REQUEST_MULTIPLE": "여러장 판독의뢰",
  "MODAL_TXT_GUIDE1": "판독의뢰 하지 않은 영상만 조회됩니다.",
  "MODAL_TXT_GUIDE2": "판독 완료된 영상만 조회됩니다.",
  "MODAL_TXT_GUIDE3": "·판독소견서는 C.C 없이 출력됩니다. 내용 수정 가능합니다.",
  "MODAL_TXT_GUIDE4": "·사인 이미지 등록하시면 함께 인쇄됩니다.",
  "MODAL_TXT_GUIDE5": "\"내정보 → 개인정보 변경\" 에서 서명파일을 등록 해주세요.",
  "MODAL_TXT_DOWNLOAD_PDF": "잠시후 다운로드가 시작됩니다.",
  "MODAL_TXT_FAIL_DOWNLOAD_PDF": "pdf 저장에 실패하였습니다.\n다시 시도해주세요.",
  "MODAL_BTN_UPLOAD": "서명파일 업로드",
  "MODAL_BTN_CLOSE": "닫기",
  "MODAL_BTN_SAVE_PDF": "PDF 다운로드",
  "MODAL_BTN_PRINT": "인쇄하기",
  "MODAL_BTN_REQUEST": "판독의뢰",
  "MODAL_THEAD_TYPE": "영상종류",
  "MODAL_THEAD_CHART_ID": "차트번호",
  "MODAL_THEAD_NAME": "이름",
  "MODAL_THEAD_DATE": "촬영일시",

  "FILTER_TXT_TODAY": "오늘",
  "FILTER_TXT_WEEK": "일주일",
  "FILTER_TXT_MONTH": "한달",
  "FILTER_TXT_ALLTIME": "전체",
  "FILTER_TXT_ENTER_KEYWORD": "환자명 또는 차트번호를 입력하세요.",
  "FILTER_TXT_TYPE": "영상종류",
  "FILTER_TXT_ALLTYPE": "전체",
  "FILTER_TXT_STATUS": "판독상태",
  "FILTER_TXT_ALLSTATUS": "전체",
  "FILTER_TXT_REQUESTED": "판독의뢰",
  "FILTER_TXT_COMPLETED": "판독완료",
  "FILTER_TXT_UNREADABLE": "판독불가",
  "FILTER_TXT_CHAT": "채팅상담 있음",
  "FILTER_TXT_SPECIFIC": "특이소견 있음",
  "FILTER_BTN_SEARCH": "검색하기",
  "FILTER_BTN_CLEAR": "초기화",
  "FILTER_BTN_ALLTIME": "전체기간",

  "LIST_TXT_REQUESTED": "판독의뢰",
  "LIST_TXT_COMPLETED": "판독완료",
  "LIST_TXT_UNREADABLE":"판독불가",
  "LIST_TXT_NO_EXAM_FOUNDED":"검색결과가 없습니다.",
  "LIST_BTN_SCAN": "원하는 환자가 보이지 않아요",
  "LIST_ALERT_TXT_FAIL_RECV_EXAMS": "영상목록 조회에 실패하였습니다.\n다시 시도해주세요.",
  "LIST_ALERT_TXT_FAIL_RECV_EXAM": "영상 조회에 실패하였습니다.\n다시 시도해주세요.",
  "LIST_ALERT_TXT_FAIL_RECV_NEW_CHAT_EXAMS": "새로운 채팅목록을 영상목록 상위에 노출하는 것에 실패하였습니다.",

  "MAIN_ALERT_TXT_CONFIRM_MIC_ACCESS":"채팅 수신음을 듣기 위해서는 브라우저 마이크 엑세스를 허용해 주세요.",
  "MAIN_TXT_EMPTY": "<strong>좌측리스트에서<br>환자 영상을 선택하세요.</strong>",
  "MAIN_BTN_REQUEST": "영상 판독 의뢰하기",
  "MAIN_BTN_CANCEL": "취소",
  "MAIN_BTN_REQUEST2": "판독의뢰",
  "MAIN_MODAL_TITLE": "영상 판독 의뢰하기",
  "MAIN_MODAL_GUIDE1": "· C.C 선택 및 입력은 선택사항입니다.",
  "MAIN_MODAL_GUIDE2": "· 영상치의학 전문의가 영상판독 해드립니다.",
  "MAIN_MODAL_GUIDE3": "· 영상판독이 완료되면 판독소견서를 인쇄할 수 있습니다.",
  "MAIN_TXT_SELECT_CC": "C.C 선택",
  "MAIN_TXT_CC_OPT1": "사랑니발치",
  "MAIN_TXT_CC_OPT2": "과잉치·매복치",
  "MAIN_TXT_CC_OPT3": "턱관절장애(TMJ)",
  "MAIN_TXT_CC_OPT4": "근관치료 실패 원인 확인",
  "MAIN_TXT_CC_OPT5": "3cm 이상 병소",
  "MAIN_TXT_CC_OPT6": "C.C 직접 입력",
  "MAIN_TXT_CC_OPT7": "선택 안함",
  "MAIN_TXT_ENTER_CC": "C.C 입력하세요.",
  "MAIN_TXT_REPORT": "판독소견서",
  "MAIN_DISEASES_TABLE_TXT_CATEGORY": "질환분류",
  "MAIN_DISEASES_TABLE_TXT_NAME": "질환명",
  "MAIN_DISEASES_TABLE_TXT_POSITION": "위치",
  "MAIN_DISEASES_TABLE_TXT_ADDITIONAL_EXAM": "추가검사",
  "MAIN_DISEASES_TABLE_TXT_DESCRIPTION": "상세설명",
  "MAIN_ALERT_TXT_FAIL_REQ": "판독의뢰 요청에 실패하였습니다.\n다시 시도해주세요.",
  "MAIN_ALERT_TXT_RECV_COMPLETE_REQ": "영상이 판독 완료되었습니다.",

  "CHATTING_TXT_HEADER": "PANVI",
  "CHATTING_TXT_PLACEHOLDER": "메시지를 입력해주세요.",
  "CHATTING_TXT_BUTTON": "보내기",
  "CHATTING_TXT_RECV": "채팅이 수신되었습니다.",
  "CHATTING_TXT_FAIL_LOAD_CHATTING_LIST": "해당 영상의 채팅목록을 조회하는것에 실패하였습니다.\n다시 시도해주세요.",

  "REPORT_TXT_TITLE": "판독소견서",
  "REPORT_TXT_PATIENT": "촬영<br>환자",
  "REPORT_TXT_PATIENT_NAME": "이름",
  "REPORT_TXT_PATIENT_AGE": "나이",
  "REPORT_TXT_PATIENT_GENDER": "성별",
  "REPORT_TXT_CHART_NUMBER": "차트번호",
  "REPORT_TXT_EXAM_DATE": "촬영일시",
  "REPORT_TXT_IMAGE_TYPE": "영상종류",
  "REPORT_TXT_PLACEHOLDER": "내용을 입력하세요.",
  "REPORT_TXT_RADIOLOGIST": "판독자",
  "REPORT_TXT_HOSPITAL_NAME": "치과명",
  "REPORT_TXT_DIAGNOSIS_DATE": "판독일자",
  "REPORT_TXT_LICENSE_NUMBER": "면허번호",
  "REPORT_TXT_RADIOLOGIST_NAME": "성명",
  "REPORT_TXT_SEAL": "(인)",
  "REPORT_TXT_IMAGE_REQUIREMENT": "이미지 비율 1대1, 해상도 100Ppx 이상 권장",
  "REPORT_TXT_BUTTON_UPLOAD_FILE": "서명파일 업로드",
  "REPORT_TXT_BUTTON_CANCEL": "취소",
  "REPORT_TXT_BUTTON_SAVE": "내용저장",
  "REPORT_TXT_BUTTON_DOWNLOAD_PDF": "PDF 다운로드",
  "REPORT_TXT_SIGN_REMOVE_ALERT": '서명이미지를 삭제하시겠습니까?',
  "REPORT_TXT_ALERT_FAIL_SAVE": '변경된 판독소견서 내용을 저장하는 것에 실패하였습니다.',

  "ALARM_HISTORY_TXT_COMPLETE": "판독완료",
  "ALARM_HISTORY_TXT_UNREADABLE": "판독불가",
  "ALARM_HISTORY_TXT_NEW_MESSAGE": "새 메시지",
  "ALARM_HISTORY_TXT_MINUTES_AGO": "분 전",
  "ALARM_HISTORY_TXT_HOURS_AGO": "시간 전",
  "ALARM_HISTORY_TXT_YESTERDAY": "어제",

  "REQUEST_HISTORY_TXT_TOTAL_BILL": "총 청구금액",
  "REQUEST_HISTORY_TXT_WHETHER_TO_PAY": "납부여부",
  "REQUEST_HISTORY_TXT_MONTH_JAN": "1월",
  "REQUEST_HISTORY_TXT_MONTH_FEB": "2월",
  "REQUEST_HISTORY_TXT_MONTH_MAR": "3월",
  "REQUEST_HISTORY_TXT_MONTH_APR": "4월",
  "REQUEST_HISTORY_TXT_MONTH_MAY": "5월",
  "REQUEST_HISTORY_TXT_MONTH_JUN": "6월",
  "REQUEST_HISTORY_TXT_MONTH_JUL": "7월",
  "REQUEST_HISTORY_TXT_MONTH_AUG": "8월",
  "REQUEST_HISTORY_TXT_MONTH_SEP": "9월",
  "REQUEST_HISTORY_TXT_MONTH_OCT": "10월",
  "REQUEST_HISTORY_TXT_MONTH_NOV": "11월",
  "REQUEST_HISTORY_TXT_MONTH_DEC": "12월",
  "REQUEST_HISTORY_TXT_FOOTER_PRICE_INFO": "· &nbsp;<span>PANORAMA 1,000원/건    CBCT 7,000원/건</span><span style='color:#9192F6; margin-left:10px; font-weight:900'>(자동전송 신청 시 30% 할인)</span>",
  "REQUEST_HISTORY_TXT_FOOTER_RECEIPT_INFO": "· &nbsp;<span>비용처리할 수 있는 계산서를 발행해 드립니다.</span>",
  "REQUEST_HISTORY_CLOSE": "닫기",
  "REQUEST_HISTORY_ALERT_FAIL_LOAD_LIST": "판독 건수 목록을 조회하는 것에 실패하였습니다.\n다시 시도해주세요.",

  "MY_INFO_TXT_EDIT_PRIVATE_INFO": "개인정보 변경",
  "MY_INFO_TXT_LOG_OUT": "로그아웃",
  "MY_INFO_BUTTON_CLOSE": "닫기",
  "MY_INFO_TXT_REQ_HISTORY": "판독의뢰 내역",

  "LOGOUT_TXT_TITLE": "로그아웃 하시겠습니까?",
  "LOGOUT_BUTTON_TXT_CANCEL": "취소",
  "LOGOUT_BUTTON_TXT_CONFIRM": "로그아웃 합니다",
  "LOGOUT_ALERT_TXT": "로그아웃에 실패하셨습니다.\n다시 시도해주세요",
  
  "EDIT_INFO_TAB_TXT_DOCTOR": "의사정보",
  "EDIT_INFO_TAB_TXT_HOSPITAL": "병원정보",
  "EDIT_INFO_TAB_TXT_PASSWORD": "비밀번호 변경",
  "EDIT_INFO_TAB_TXT_SET_REQUEST": "자동의뢰 설정",
  "EDIT_INFO_DOCTOR_TAB_TOP_TXT": "성함과 아이디(이메일)은 변경하실 수 없습니다. 변경을 원하시는 경우 관리자에게 문의주세요.<br/>판독소견서에 아래 정보로 판독자 정보가 입력됩니다. ",
  "EDIT_INFO_HOSPITAL_TAB_TOP_TXT": "병원 정보는 세금계산서 발행을 위해 필요하니, 정확히 입력해주세요.<br/>세금계산서용 이메일은 입력하지 않으시면 아이디용 이메일로 입력됩니다.",
  "EDIT_INFO_DOCTOR_NAME": "의사명 *",
  "EDIT_INFO_DOCTOR_EMAIL": "아이디(이메일) *",
  "EDIT_INFO_DOCTOR_PHONE": "휴대폰 번호 *",
  "EDIT_INFO_DOCTOR_LICENSE_NO": "의사 면허번호 *",
  "EDIT_INFO_DOCTOR_LICENSE_FILE": "의사면허증",
  "EDIT_INFO_DOCTOR_SIGN": "판독의 서명",
  "EDIT_INFO_DOCTOR_SIGN_UPLOAD_TXT": "서명이미지를 등록하시면 서명된 판독소견서가 조회됩니다.",
  "EDIT_INFO_DOCTOR_SIGN_UPLOAD_REQUIREMENT": "이미지 비율 1대1, 해상도 100px 이상을 권장합니다.",
  "EDIT_INFO_HOSPITAL_NAME": "병원명 *",
  "EDIT_INFO_HOSPITAL_CEO": "대표자명 *",
  "EDIT_INFO_HOSPITAL_BUSINESS_NO": "사업자등록번호 *",
  "EDIT_INFO_HOSPITAL_ADDRESS": "병원 주소 *",
  "EDIT_INFO_HOSPITAL_CONTACT": "병원 전화번호 *",
  "EDIT_INFO_HOSPITAL_EMAIL_FOR_TAX": "계산서용 이메일",
  "EDIT_INFO_HOSPITAL_BUSINESS_FILE": "사업자등록증",
  "EDIT_INFO_HOSPITAL_CEO_TXT_PLACEHOLDER":"홍길동",
  "EDIT_INFO_HOSPITAL_ADDRESS_TXT_PLACEHOLDER":"주소",
  "EDIT_INFO_HOSPITAL_NAME_ALERT_TXT": "병원명을 입력하세요.",
  "EDIT_INFO_HOSPITAL_CEO_ALERT_TXT": "대표자명을 입력하세요.",
  "EDIT_INFO_HOSPITAL_BUSINESS_NO_ALERT_TXT": "사업자등록번호를 입력하세요.",
  "EDIT_INFO_HOSPITAL_ADDRESS_ALERT_TXT": "주소를 입력하세요.",
  "EDIT_INFO_HOSPITAL_CONTACT_ALERT_TXT": "유효한 번호를 입력하세요.",
  "EDIT_INFO_PASSWORD_TXT": "비밀번호",
  "EDIT_INFO_PASSWORD_TXT_CONFIRM": "비밀번호 확인",
  "EDIT_INFO_PASSWORD_ALERT_TXT": "8자 이상 필수, 영문 대소문자/숫자/특수문자로 조합 가능",
  "EDIT_INFO_PASSWORD_ALERT_RENEW":"비밀번호가 일치하지 않습니다.",
  "EDIT_INFO_PASSWORD_TXT_PLACEHOLDER": "새 비밀번호를 입력해주세요.",
  "EDIT_INFO_PASSWORD_TXT_PLACEHOLDER_AGAIN": "한번 더 입력해주세요.",
  "EDIT_INFO_TXT_HOSPITAL_ADDRESS": "병원 주소를 입력해 주세요",
  "EDIT_INFO_TXT_HOSPITAL_CONTACT": "병원 전화번호를 입력해 주세요.",
  "EDIT_INFO_TXT_EMAIL_FOR_TAX": "세금계산서용 이메일을 입력해 주세요.",
  "EDIT_INFO_TXT_POLICY_AUTO_REQ_PX": "파노라마 자동의뢰",
  "EDIT_INFO_TXT_POLICY_AUTO_REQ_CT": "CBCT 자동의뢰",
  "EDIT_INFO_TXT_REQ_NEW_PATIENT":"신규환자",
  "EDIT_INFO_TXT_REQ_POLICY_HALF_YEAR":"6개월 내 첫방문",
  "EDIT_INFO_TXT_REQ_POLICY_A_YEAR":"12개월 내 첫방문",
  "EDIT_INFO_TXT_REQ_REJECT":"자동의뢰 하지 않음",
  "EDIT_BUTTON_UPLOAD_FILE": "파일등록",
  "EDIT_INFO_SUCCESS_CHANGE_PASSWD": "비밀번호를 변경하는것에 성공하였습니다.",
  "EDIT_INFO_FAIL_CHANGE_PASSWD": "비밀번호를 변경하는것에 실패하였습니다.",
  "EDIT_INFO_SUCCESS_CHANGE_DOCTOR": "의사정보를 변경하는것에 성공하였습니다.",
  "EDIT_INFO_FAIL_CHANGE_DOCTOR": "의사정보를 변경하는것에 실패하였습니다.",
  "EDIT_INFO_SUCCESS_CHANGE_HOSPITAL": "병원정보를 변경하는것에 성공하였습니다.",
  "EDIT_INFO_FAIL_CHANGE_HOSPITAL": "병원정보를 변경하는것에 실패하였습니다.",
  "EDIT_INFO_BUTTON_CLOSE": "닫기",
  "EDIT_INFO_BUTTON_SAVE": "변경 저장",
  
  "SIGNUP_TITLE_SIGNUP": "회원 가입",
  "SIGNUP_ALERT_TXT_CHECK_REQUIRED_TERMS": "필수 약관을 체크하세요.",
  "SIGNUP_TXT_ID": "아이디(이메일)",
  "SIGNUP_TXT_PASSWORD": "비밀번호",
  "SIGNUP_BTN_ID_DOUBLE_CHECK": "중복확인",
  "SIGNUP_TXT_ID_DOUBLE_CHECK_COMPLETE":"중복확인 완료",
  "SIGNUP_TXT_ALERT_DUPLICATED_EMAIL":"중복되는 아이디 입니다.",
  "SIGNUP_TXT_ID_DOUBLE_CHECK_NOTICE":"중복 확인을 클릭해 주세요",
  "SIGNUP_TXT_PASSWORD_PLACEHOLDER":"비밀번호를 입력하세요",
  "SIGNUP_TXT_PASSWORD_NOTICE":"8자 이상 필수, 영문 대소문자/숫자/특수문자로 조합 가능",
  "SIGNUP_TXT_PHONE_NOTICE": "유효한 번호를 입력하세요",
  "SIGNUP_TXT_HOSPITAL_CONTACT": "병원 전화번호",
  "SIGNUP_TXT_CONTACT_PLACEHOLDER":"020000000",
  "SIGNUP_TXT_PHONE_PLACEHOLDER":"01000000000",
  "SIGNUP_TXT_PHONE":"휴대폰 번호",
  "SIGNUP_TXT_CERTIFICATE_OF_BUSINESS_REGISTRATION_AND_LICENSE_NECESSITY": "<div><strong style='text-decoration:underline;'>사업자 등록증</strong>은 판독의뢰 하실 때 <strong style='text-decoration:underline;'>세금계산서 발행</strong>을 위해 필요합니다.<br><strong style='text-decoration:underline;'>의사 면허증</strong>은 판독문 작성시 <strong style='text-decoration:underline;'>판독의의 정보</strong>를 입력하기 위해 필요합니다.</div>",
  "SIGNUP_TXT_CERTIFICATE_OF_BUSINESS_REGISTRATION":"사업자등록증",
  "SIGNUP_TXT_FILE_UPLOAD_NOTICE":"pdf, jpg, png 형식의 파일을 올려주세요.",
  "SIGNUP_TXT_FILE_UPLOAD":"파일등록",
  "SIGNUP_TXT_LICENSE": "의사면허증",
  "SIGNUP_TXT_EMAIL_FOR_TAX":"세금계산서용 이메일",
  "SIGNUP_TXT_NOTICE_WITHOUT_EMAIL_FOR_TAX":"입력하지 않으시면 세금계산서는 아이디용 이메일로 발송됩니다.",
  "SIGNUP_TXT_NOTICE_FOR_ACCURATE_WRITING_OF_BUSINESS_INFO":"사업자등록증 파일등록 안하신 경우 입력해주세요.",
  "SIGNUP_TXT_NOTICE_FOR_ACCURATE_WRITING_OF_LICENSE_INFO":"의사면허증 파일등록 안하신 경우 입력해주세요.",
  "SIGNUP_TXT_HOSPITAL_NAME": "병원명",
  "SIGNUP_TXT_HOSPITAL_REPRESENTATIVE":"대표자명",
  "SIGNUP_TXT_HOSPITAL_BUSINESS_NUMBER": "사업자 등록번호",
  "SIGNUP_TXT_HOSPITAL_ADDRESS": "병원주소",
  "SIGNUP_TXT_DOCTOR_NAME": "원장님 성함",
  "SIGNUP_TXT_LICENSE_NUMBER": "면허번호",
  "SIGNUP_TXT_TERMS_ALL_AGREE":"모두동의",
  "SIGNUP_TXT_TERMS_OF_SERVICE":"판비서비스 이용약관 동의",
  "SIGNUP_TXT_PRIVATE_INFO_COLLECTION":"개인정보 수집 및 이용 동의",
  "SIGNUP_TXT_MARKETING_INFO_RECEIVING":"마케팅 정보 수신 동의 (선택)",
  "SIGNUP_TXT_ALREADY_UPLOAD_BUSINESS_FILE": "사업자등록증 파일 등록 완료",
  "SIGNUP_TXT_ALREADY_UPLOAD_LICENSE_FILE": "의사면허증 파일 등록 완료",
  "SIGNUP_TXT_SEE_TERMS":"보기",
  "SIGNUP_BTN_PREV":"이전",
  "SIGNUP_BTN_NEXT":"다음",
  "SIGNUP_BTN_NO_IMG_FILE":"이미지 파일이 없어요",
  "SIGNUP_BTN_CANCEL":"나중에 가입하기",
  "SIGNUP_BTN_SIGNUP": "판비 시작하기",
  "SIGNUP_MSG_DONE": "가입신청을 완료하였습니다 :)",
  "SIGNUP_MSG_FAIL": "가입신청에 실패하셨습니다.\n다시 신청해주세요.",


  "PRIVACY_POLICY_TITLE": "개인정보 처리방침",
  "PRIVACY_POLICY_CLOSE": "확인",
  "PRIVACY_POLICY": `
    <p>
    주식회사 인비전랩(이하 ‘회사’라 한다)은 사이트 운영에 있어서 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)과 개인정보 보호법 등 대한민국의 개인정보 관련 법령 및
    개인정보 보호지침 등 규정을 준수합니다.
    <br>
    이에 회사는 이용자의 개인정보를 보호하고 이와 관련된 고충을 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립하여 공개합니다.
    <br>
    <br>
    제1조 처리하는 개인정보 항목과 수집 방법
    <br>
    회사는 다음의 개인정보 항목을 처리하고 있습니다.
    <br>
    1. 서비스 가입 및 이용을 위한 회원 정보
    <br>
    - 필수: 의원명, 대표의사 성명, 담당자 성명, 담당자 연락처, 담당자 이메일 주소, 보유하고 있는 파노라마 장비 브랜드 등
    <br>
    - 선택: 의원 전화번호, 의원 주소, 소속 의료인에 관한 정보 등
    <br>
    2. 서비스의 제공을 위한 파노라마 영상 (단, 파노라마 영상은 사이트에 업로드되는 즉시 개인을 특정할 수 없도록 비식별처리됩니다.)
    <br>
    3. 이용자 민원 관련 정보
    <br>
    - 필수: 이름, 전화번호, 이메일 등
    <br>
    - 선택: 건강정보(치료영역, 질의분야, 안전성 정보 내용 및 기간 등) 등
    <br>
    4. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
    <br>
    - IP 주소, 서비스 이용 기록, 기기정보, 위치정보
    <br>
    <br>
    회사는 다음과 같은 방법 또는 과정을 통하여 위의 개인정보를 수집합니다.
    <br>
    - 서비스 회원가입을 위하여 계정을 생성하는 과정에서 수집
    <br>
    - 서비스 제공을 위한 소프트웨어 설치 등 필수적인 과정에서 수집
    <br>
    - 서비스에 대한 피드백 또는 민원 제공 과정에서 수집
    <br>
    <br>
    <br>
    제2조 개인정보의 처리 목적
    <br>
    회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
    동의를 받는 등 필요한 조치를 이행할 예정입니다.
    <br>
    1. 이용자의 가입 의사 확인, 이용자에 대한 서비스 제공에 따른 본인 식별·인증, 서비스 부정이용 방지, 이메일 발송 등 각종 고지·통지, 이용자 탈퇴 의사 확인
    <br>
    <br>
    주식회사 인비전랩(이하 ‘회사’라 한다)은 사이트 운영에 있어서 정보통신망 이용촉진 및 정보보호 등에 관한 법률(이하 ‘정보통신망법’)과 개인정보 보호법 등 대한민국의 개인정보 관련 법령 및
    개인정보 보호지침 등 규정을 준수합니다.
    <br>
    이에 회사는 이용자의 개인정보를 보호하고 이와 관련된 고충을 원활하게 처리할 수 있도록 다음과 같이 개인정보 처리방침을 수립하여 공개합니다.
    <br>
    <br>
    제1조 처리하는 개인정보 항목과 수집 방법
    <br>
    회사는 다음의 개인정보 항목을 처리하고 있습니다.
    <br>
    1. 서비스 가입 및 이용을 위한 회원 정보
    <br>
    - 필수: 의원명, 대표의사 성명, 담당자 성명, 담당자 연락처, 담당자 이메일 주소, 보유하고 있는 파노라마 장비 브랜드 등
    <br>
    - 선택: 의원 전화번호, 의원 주소, 소속 의료인에 관한 정보 등
    <br>
    2. 서비스의 제공을 위한 파노라마 영상 (단, 파노라마 영상은 사이트에 업로드되는 즉시 개인을 특정할 수 없도록 비식별처리됩니다.)
    <br>
    3. 이용자 민원 관련 정보
    <br>
    - 필수: 이름, 전화번호, 이메일 등
    <br>
    - 선택: 건강정보(치료영역, 질의분야, 안전성 정보 내용 및 기간 등) 등
    <br>
    4. 인터넷 서비스 이용과정에서 아래 개인정보 항목이 자동으로 생성되어 수집될 수 있습니다.
    <br>
    - IP 주소, 서비스 이용 기록, 기기정보, 위치정보
    <br>
    <br>
    회사는 다음과 같은 방법 또는 과정을 통하여 위의 개인정보를 수집합니다.
    <br>
    - 서비스 회원가입을 위하여 계정을 생성하는 과정에서 수집
    <br>
    - 서비스 제공을 위한 소프트웨어 설치 등 필수적인 과정에서 수집
    <br>
    - 서비스에 대한 피드백 또는 민원 제공 과정에서 수집
    <br>
    <br>
    <br>
    제2조 개인정보의 처리 목적
    <br>
    회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법 제18조에 따라 별도의
    동의를 받는 등 필요한 조치를 이행할 예정입니다.
    <br>
    1. 이용자의 가입 의사 확인, 이용자에 대한 서비스 제공에 따른 본인 식별·인증, 서비스 부정이용 방지, 이메일 발송 등 각종 고지·통지, 이용자 탈퇴 의사 확인
    <br>
  </p>
  `
}