<template>
  <div class="request_history_modal">
    <div class="request_history_box">
      <div class="title">
        <h3>{{lang[$store.state.lang].MENU_HISTORY}}<!--판독의뢰 통계--></h3>
        <button class="close_btn" @click="$emit('close_req_history_modal')">
          <img src="https://panvi.kr/images/dise_pu_close-48048e892d290a5210d9..png" alt="close_btn">
        </button>
      </div>
      <div class="history_main">
        <div class="year_controller">
          <button @click="goPrevYear">
            <img src="https://panvi.kr/images/rrd_pu_prev-cc2fd025338d4b9bf5de..png" alt="previous_year">
          </button>
          <div class="year_txt">
            <h3>{{year}}</h3>
          </div>
          <button @click="goNextYear">
            <img src="https://panvi.kr/images/rrd_pu_next-31951d03815a7bde4e76..png" alt="next_year">
          </button>
        </div>
        <table>
          <div class="loading_bg" v-if="!examsAreLoaded" ref="loadingBar">
            <div class="spinner"></div>
          </div>
          <thead>
            <tr>
              <th class="col_month"></th>
              <th class="col_normal">PANORAMA</th>
              <th class="col_normal">CBCT</th>
              <th class="col_normal">{{lang[$store.state.lang].REQUEST_HISTORY_TXT_TOTAL_BILL}}<!--총 청구금액--></th>
              <th class="col_check">{{lang[$store.state.lang].REQUEST_HISTORY_TXT_WHETHER_TO_PAY}}<!--납부여부--></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(monthObj,idx) in requestArr" :key="'requestArr-'+idx+'-'+monthObj.month+'-'+'월'">
              <td class="col_month">{{lang[$store.state.lang]['REQUEST_HISTORY_TXT_MONTH_'+months[idx]]}}<!--1월 ~ 12월--></td>
              <td class="col_normal">{{monthObj.px > 0 ? monthObj.px : '-'}}</td>
              <td class="col_normal">{{monthObj.ct > 0 ? monthObj.ct : '-'}}</td>
              <td class="col_normal">{{(monthObj.px * priceInfo.px + monthObj.ct * priceInfo.ct) == 0? '-' : __putThousandSeparator((monthObj.px * 1000 + monthObj.ct * 7000))}}</td>
              <td class="col_check">-</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="note_wrap">
        <p v-html="lang[$store.state.lang].REQUEST_HISTORY_TXT_FOOTER_PRICE_INFO"></p><!--PANORAMA 1,000원/건 CBCT 7,000원/건 (자동전송 신청 시 30% 할인)-->
        <p v-html="lang[$store.state.lang].REQUEST_HISTORY_TXT_FOOTER_RECEIPT_INFO"></p> <!--비용처리할 수 있는 계산서를 발행해 드립니다.-->
      </div>
      <div class="btn_wrap">
        <button @click="$emit('close_req_history_modal')">{{lang[$store.state.lang].REQUEST_HISTORY_CLOSE}}<!--확인--></button>
      </div>
    </div>
  </div>
</template>

<script>
import lang from '@/lang'
import wsMixin from '@/wsMixin'
import alert from '@/alert'
export default {
  name : 'RequestHistoryModal',
  mixins: [wsMixin],
  data(){
    return{
      lang,
      year : new Date().getFullYear(),
      requestArr : [],
      priceInfo: {
        px: 1000,
        ct: 7000
      },
      months: ['JAN','FEB','MAR','APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
      examsAreLoaded: false,
    }
  },
  methods : {
    alert,
    onRecv(data){
      if(!data.body[0].content.success) {
        if(process.env.VUE_APP_DEBUG) console.log(`action ${data.action} id = ${data.id} fail`,data);
      }
      /*
      ** 157번 action(= 영상목록 조회)에 대한 응답이 수신되었을 때의 처리
      ** id = 2 해당 컴포넌트가 마운트되어 올 해 판독의뢰 목록을 요청하는 경우 
      */
     if(data.action == 157){
        if(data.id == 2) {
          // 응답이 성공인 경우에 대한 처리
          if(data.body[0].content.success){
            this._resetReqArr();
            // 수신된 영상목록을 순회하며 영상의 종류에 따라 영상의 요청일에 해당하는 월 객체에 1을 더해준다.
            data.body[1].content.forEach((exam)=>{
              let month = new Date(exam.requested_at).getMonth()+1;
              // 영상의 종류가 'PX'인 경우에 대한 처리
              if(exam.type == 'PX') this.requestArr[month-1].px+=1;
              // 영상의 종류가 'CT'인 경우에 대한 처리
              else this.requestArr[month-1].ct+=1;
            })
          }else this.alert('error',this.lang[this.$store.state.lang].REQUEST_HISTORY_ALERT_FAIL_LOAD_LIST); // 판독 건수 목록을 조회하는 것에 실패하였습니다.\n다시 시도해주세요.
          this.examsAreLoaded = true;
        }
      }
    },
    _resetReqArr(){
      this.requestArr = [];
      // 0 ~ 11 까지 반목하여 12개의 월 객체를 requestArr에 push하여 기본 형태를 갖추도록 한다.
      for(let month=0; month<12; month++){
        this.requestArr.push({
          month: month+1,
          px: 0,
          ct: 0
        })
      }
    },
    goPrevYear(){
      this.examsAreLoaded = false;
      this.year -= 1;
    },
    goNextYear(){
      this.examsAreLoaded = false;
      this.year += 1;
    },
    __putThousandSeparator(charge){
      let num = 0;
      let chargeArr = charge.toString().split('');
      // 문자열의 길이만큼 반복문을 실행하여 , 를 어디에 추가해야 할지 알아낸다.
      for(let i=chargeArr.length; i--;){
        num += 1;
        // 반복문이 3의 배수 번째 실행되었고, 문자열의 0번째 index에 해당하지 않는 경우에 ,를 추가한다. 
        if(num % 3 === 0 && i!=0)  chargeArr.splice(i,0,',');
      }
      return chargeArr.join('');
    },
    _requestExams(){
      const packet = this.$$build(157,2,[{
        contentType : 1,
        content : {
          filter: {
            dateFrom : `${this.year}-01-01`,
            dateTo : `${this.year}-12-${new Date(this.year,12,0).getDate()}`,
            status : 'completed'
          }
        }
      }]);
      this.$socket.send(packet);
    }
  },
  mounted(){
    this._requestExams();
  },
  watch: {
    year(){
      this._requestExams();
    },
  }
}
</script>

<style scoped>
  .request_history_modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 2;
  }
  .request_history_box{
    display: grid;
    width: 700px;
    height: 831px;
    box-sizing: border-box;
    grid-template-rows: 60px 1fr 120px 45px;
    background-color: #1A1A1B;
    border-radius: 8px;
    padding: 40px;
    border: 1px solid #404040;
    font-family: "Noto Sans KR", sans-serif;
  }
  .title{
    position: relative;
  }
  .title h3{
    color: #fff;
    font-size: 24px;
    font-weight: 500;
  }
  .close_btn{
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .history_main{
    width: 600px;
    display: grid;
    grid-template-rows: 60px 1fr;
  }
  .year_controller{
    display: grid;
    grid-template-columns: 40px 80px 40px;
  }
  .year_controller button{
    display: grid;
    place-items: center center;
  }
  .year_txt h3{
    text-align: center;
    color: #EEECF1;
    font-weight: 700;
    font-size: 20px;
  }
  .year_txt{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  table{
    position: relative;
    height: 100%;
  }
  thead{
    height: 44px;
  }
  tbody{
    height: 100%;
    display: grid;
    grid-template-rows: repeat(12, 1fr);
  }
  tr{
    height: 100%;
    display: flex;
    align-items: center;
    color: #B7B7B7;
    border-bottom: 1px solid #262529;
    font-size: 14px;
    text-align: center;
  }
  .col_month{
    width: 100px;
  }
  .col_check{
    width: 120px;
  }
  .col_normal{
    flex:1
  }
  .note_wrap{
    padding-left: 20px;
    padding-top: 20px;
  }
  .note_wrap p{
    padding-top: 8px;
    color: #DFDEE0;
    font-size: 14px;
    font-weight: 500;
  }
  .emphasize{
    margin-left: 10px;
    color: #9192F6;
    font-weight: 900;
  }
  .btn_wrap{
    display: grid;
    place-items: center center;
  }
  .btn_wrap button{
    width: 100px;
    height: 100%;
    color: white;
    background-color: #0e0e0e;
    border: 1px solid #5510B5;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 900;
  }
  .btn_wrap button:hover{
    background: #9560F5;
  }
  .loading_bg{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading_bg>.spinner{
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid transparent;
    border-top-color: var(--color-selected);
    border-bottom-color: var(--color-selected);
    animation: spinner .8s ease infinite;
    z-index: 3;
  }
</style>
